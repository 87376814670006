import {
    CHANGE_RPT_FILTER,
    CHANGE_SERVICEGROUP_SUCCESS,
    GET_RPT_FAIL,
    GET_RPT_LIST_START,
    GET_RPT_LIST_SUCCESS,
    GET_RPT_START,
    GET_RPT_SUCCESS,
    RESET_RPT,
    RESET_RPT_FILTERS,
    UPDATE_RPT_HIDDEN_COLUMNS
} from "../actions/types";

const initialState = {
    reports: [],
    fetching: true,
    dropdowns: {},
    filters: {},
    hiddenColumns: [],
    data: []
};

const reportReducer = function(state = initialState, action) {
    switch (action.type) {
        case CHANGE_SERVICEGROUP_SUCCESS:
        case RESET_RPT:{
            return {
                ...initialState
            };
        }
        case RESET_RPT_FILTERS:
            return {
                ...state,
                filters: {}
            };
        case GET_RPT_START:
        case GET_RPT_LIST_START:
            return {
                ...state,
                fetching: true
            };
        case GET_RPT_SUCCESS: {
            return {
                ...state,
                ...action.payload,
                fetching: false
            };
        }
        case GET_RPT_LIST_SUCCESS: {
            return {
                ...state,
                reports: action.payload,
                fetching: false
            };
        }
        case CHANGE_RPT_FILTER:{
            const { name, value } = action.payload;
            const filters = {
                ...state.filters,
                [name]: value
            };
            if (!value) delete filters[name];
            return {
                ...state,
                filters
            };
        }
        case UPDATE_RPT_HIDDEN_COLUMNS: {
            const { name } = action.payload;
            if (state.hiddenColumns.includes(name)) {
                const hiddenColumns = state.hiddenColumns.filter(item => item !== name);
                return {
                    ...state,
                    hiddenColumns
                };
            }
            const hiddenColumns = [...state.hiddenColumns, name];
            return {
                ...state,
                hiddenColumns
            };
        }

        case GET_RPT_FAIL:
        default:
            return state;
    }
};

export default reportReducer;
