import React from "react";
import { connect } from "react-redux";
import DateTime from "./base/DateTime";
import { format, isValid } from "date-fns";
import { handleFieldChange } from "../../actions/formActions";

const DateTimeField = ({ name, handleFieldChange, onChange, ...allOtherProps }) => {
    const handleChange = value => {
        if (isValid(value) || value === null) {
            value = value === null ? "" : format(value, "MM/dd/yyyy hh:mm:ss a");
            handleFieldChange({ name, value });
            if (typeof onChange === "function") onChange(value);
        }
    };

    return <DateTime {...allOtherProps} onChange={handleChange} name={name} />;
};

const mapsStateToProps = (state, { name }) => {
    return {
        value: state.form.data[name],
        error: state.form.errors[name]
    };
};

const mapDispatchToProps = { handleFieldChange };

export default connect(mapsStateToProps, mapDispatchToProps)(DateTimeField);
