import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleFieldChange, handleFieldCopy } from "../../actions/formActions";
import { Checkbox, FormControl, FormControlLabel, FormHelperText, FormLabel, makeStyles } from "@material-ui/core";
import { tblRef } from "../../common/consts";
   
const getStyles = makeStyles(theme => ({
    helper: {
        margin: 0,
        marginTop: -10,
        paddingLeft: theme.spacing(4)
    },
    terms: {
        marginTop: theme.spacing(1.5)
    }
}));

const TermsAndConditions = ({ label, name, color = "primary", legend, helperText, onChange, terms, tblID, fieldID, generatePDFTermsConditions }) => {
    const dispatch = useDispatch();
    const classes = getStyles();
    const checked = useSelector(state => state.form.data[name]);
    const error = useSelector(state => state.form.errors[name]);

    const handleChange = e => {
        dispatch(handleFieldChange({ name, value: e.target.checked }));
        if (tblID === tblRef.attendee && generatePDFTermsConditions && e.target.checked) {
            const [, ...rest] = fieldID.split("|");
            const attendeeNameKey = `${rest.join("|")}_name`;
            dispatch(handleFieldCopy({ fromFieldName: attendeeNameKey, toFieldName: `custom_field_signatureName_${fieldID}` }));
        }
        if (typeof onChange === "function") onChange(e);
    };

    return (
        <React.Fragment>
            <div className={classes.terms}>
                <div dangerouslySetInnerHTML={{ __html: terms?.replace(/\n\n/gm, "<br />") }} />
            </div>
            <FormControl error={Boolean(error)}>
                {legend ? <FormLabel component="legend">{legend}</FormLabel> : null}
                <FormControlLabel control={<Checkbox color={color} checked={Boolean(checked)} />} label={label} onChange={handleChange} />
                {error || helperText ? <FormHelperText className={classes.helper}>{error || ""}{error && helperText ? " - " : ""}{helperText || ""}</FormHelperText> : null}
            </FormControl>
        </React.Fragment>
    );
};

export default React.memo(TermsAndConditions);
