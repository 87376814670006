import React from "react";
import PropTypes from "prop-types";
import { KeyboardTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { makeStyles } from "@material-ui/core";
import DatefnsUtils from "@date-io/date-fns";
import format, { types } from "../../../helpers/format";

const getClasses = makeStyles({
    btn: {
        "& > button": {
            padding: 0
        }
    }
});

const Time = ({ name, label, value, helperText, error, onChange, ...allOtherProps }) => {
    const styles = getClasses();
    const {
        autoComplete,
        autoFocus,
        classes,
        className,
        color,
        defaultValue,
        disabled,
        FormHelperTextProps,
        fullWidth = true,
        InputLabelProps,
        InputProps,
        inputProps,
        inputRef,
        inputVariant = "outlined",
        margin,
        placeholder = "HH:MM PM",
        required,
        size,
        onBlur,
        variant
    } = allOtherProps;

    const eligibleProps = {
        autoComplete,
        autoFocus,
        classes,
        className,
        color,
        defaultValue,
        disabled,
        FormHelperTextProps,
        fullWidth,
        InputLabelProps,
        InputProps,
        inputProps,
        inputRef,
        inputVariant,
        margin,
        placeholder,
        required,
        size,
        onBlur,
        variant
    };

    if (value) value = format(types.DATETIME, value);
    return (
        <MuiPickersUtilsProvider utils={DatefnsUtils}>
            <KeyboardTimePicker
                {...eligibleProps}
                id={name}
                autoOk
                label={label}
                value={value ? value : null}
                format="hh:mm a"
                clearable
                helperText={`${error || ""}${error && helperText ? " - " : ""}${helperText || ""}`}
                onChange={onChange}
                error={Boolean(error)}
                InputAdornmentProps={{ classes: { root: styles.btn } }}
            />
        </MuiPickersUtilsProvider>
    );
};

Time.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.any
};

export default React.memo(Time);
