import React from "react";
import PropTypes from "prop-types";
import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

const Select = ({ name, label, value, helperText, error, onChange, options, optName, optVal, allowEmptyStringValues, ...allOtherProps }) => {
    const {
        autoHighlight = true,
        autoSelect = true,
        blurOnSelect,
        ChipProps,
        classes,
        className,
        clearOnEscape,
        clearText,
        closeIcon,
        closeText,
        debug,
        defaultValue,
        disableClearable,
        disableCloseOnSelect,
        disabled,
        disableListWrap = true,
        disablePortal,
        filterOptions,
        filterSelectedOptions,
        forcePopupIcon,
        freeSolo,
        fullWidth = true,
        getOptionDisabled,
        getOptionSelected,
        groupBy,
        includeInputInList,
        inputValue,
        ListboxComponent,
        ListboxProps,
        loading,
        loadingText,
        noOptionsText,
        onClose,
        onInputChange,
        onOpen,
        open,
        openText,
        PaperComponent,
        PopperComponent,
        popupIcon,
        renderGroup,
        renderInput,
        renderOption,
        renderTags,
        getOptionLabel,
        selectOnFocus,
        size,
        TextFieldProps,
        maxLength,
        autoComplete,
        variant = "outlined"
    } = allOtherProps;

    const eligibleProps = {
        autoHighlight,
        autoSelect,
        blurOnSelect,
        ChipProps,
        classes,
        className,
        clearOnEscape,
        clearText,
        closeIcon,
        closeText,
        debug,
        defaultValue,
        disableClearable,
        disableCloseOnSelect,
        disabled,
        disableListWrap,
        disablePortal,
        getOptionLabel,
        filterOptions,
        filterSelectedOptions,
        forcePopupIcon,
        freeSolo,
        getOptionDisabled,
        getOptionSelected,
        groupBy,
        includeInputInList,
        inputValue,
        ListboxComponent,
        ListboxProps,
        loading,
        loadingText,
        noOptionsText,
        onClose,
        onInputChange,
        onOpen,
        open,
        openText,
        PaperComponent,
        PopperComponent,
        popupIcon,
        renderGroup,
        renderInput,
        renderOption,
        renderTags,
        selectOnFocus,
        size
    };

    options = Array.isArray(options) ? options : [];
    const selected = options.filter(f => String(f[optVal]) === String(value));

    const handleChange = (e, opt, ...rest) => {
        const value = opt && ((allowEmptyStringValues && opt[optVal] !== undefined) || (!allowEmptyStringValues && opt[optVal])) ? opt[optVal] : opt;
        if (onChange && typeof onChange === "function") {
            onChange({ target: { value, name } }, opt, ...rest);
        }
    };

    return (
        <Autocomplete
            {...eligibleProps}
            options={options}
            getOptionLabel={getOptionLabel ? getOptionLabel : option => option[optName] || ""}
            value={selected[0] || null}
            onChange={handleChange}
            renderInput={params => (
                <TextField
                    {...params}
                    {...TextFieldProps}
                    name={name}
                    variant={variant}
                    fullWidth={fullWidth}
                    label={label}
                    inputProps={{ ...params.inputProps, autoComplete, maxLength }}
                    helperText={`${error || ""}${error && helperText ? " - " : ""}${helperText || ""}`}
                    error={Boolean(error)} />
            )}
        />
    );
};

Select.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.any,
    options: PropTypes.array,
    optName: PropTypes.string.isRequired,
    optVal: PropTypes.string.isRequired
};

export default React.memo(Select);
