import React from "react";
import { connect } from "react-redux";
import Dollar from "./base/Dollar";
import { handleFieldChange } from "../../actions/formActions";

const DollarField = ({ name, handleFieldChange, onChange, onBlur, value, defValue, ...allOtherProps }) => {

    React.useEffect(() => {
        if ((typeof value === "undefined" || value === null) && defValue) {
            handleFieldChange({ name, value: defValue });
        }
    }, [name, value, defValue, handleFieldChange]);
	
    const handleChange = e => {
        handleFieldChange({ name, value: e.target.value });
        if (typeof onChange === "function") onChange(e);
    };

    const handleBlur = () => {
        if (typeof onBlur === "function") onBlur({ target: { name, value } });
    };

    return <Dollar {...allOtherProps} value={value} name={name} onChange={handleChange} onBlur={handleBlur} />;
};

const mapsStateToProps = (state, { name }) => {
    return {
        value: state.form.data[name],
        error: state.form.errors[name]
    };
};

const mapDispatchToProps = { handleFieldChange };

export default connect(mapsStateToProps, mapDispatchToProps)(DollarField);
