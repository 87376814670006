import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleFieldChange } from "../../actions/formActions";
import RadioButtonGroup from "./base/RadioButtonGroup";

const RadioButtonGroupField = ({ name, onChange, ...allOtherProps }) => {
    const dispatch = useDispatch();
    const value = useSelector(state => state.form.data[name]);
    const error = useSelector(state => state.form.errors[name]);
    const options = useSelector(state => "options" in allOtherProps ? allOtherProps.options : state.form.dropdowns[name]) || [];

    const handleChange = e => {
        dispatch(handleFieldChange({ name, value: e.target.value }));
        if (typeof onChange === "function") onChange(e);
    };

    return <RadioButtonGroup name={name} value={value} error={error} options={options} onChange={handleChange} {...allOtherProps} />;
};

export default React.memo(RadioButtonGroupField);
