import { useDispatch, useSelector } from "react-redux";
import { callEndpoint } from "../actions/layoutActions";
import useDebouncedEffect from "./useDebouncedEffect";

/* since this hook is asynchronous, its causing the parent component to completely rerender twice.. need a better solution */
function useMidsByTarg(serviceGroupID) {
    const dispatch = useDispatch();
    const mids = useSelector(state => state.layout.endpoints?._api_serviceGroup_midsByTarg);
	
    /* using a timer on here to wait until serviceGroupID is populated */
    useDebouncedEffect(() => {
        if (typeof serviceGroupID !== "undefined"){
            const filters = { serviceGroupID };
            if (serviceGroupID) dispatch(callEndpoint("/api/servicegroup/midsByTarg", { filters }));
        }
        else {
            dispatch(callEndpoint("/api/servicegroup/midsByTarg"));
        }
    }, [serviceGroupID], 350);

    return mids || {};
}

export default useMidsByTarg;
