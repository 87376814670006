const setStorageItem = (key, value, session = false) => {
    const storage = session ? window.sessionStorage : window.localStorage;
    try {
        if (value) {
            storage.setItem(key, value);
        }
        else {
            storage.removeItem(key);
        }
    }
    catch (e) {
        console.log("Issue updating local storage", e);
        storage.removeItem(key);
    }
};

export default setStorageItem;
