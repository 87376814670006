import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { red } from "@material-ui/core/colors";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormHelperText, Typography, withStyles } from "@material-ui/core";
import CircularProgressWithLabel from "../CircularProgressWithLabel";
import { handleFieldChange } from "../../actions/formActions";

const styles = ({
    error: {
        color: red[500]
    }
});

const statusLabels = [
    "Error. Please try again.",
    "Please Swipe or Insert Card",
    "Reading Card Data",
    "Please Wait...",
    "Processing"
];

const states = {
    ERROR: 0,
    READY: 1,
    READING: 2,
    PENDING: 3,
    PROCESSING: 4
};

class SwipeCardField extends React.PureComponent {
    constructor(props) {
        super(props);

        this.initialState = {
            buffer: "",
            open: false,
            progress: 0,
            inputLen: 0,
            statusID: 1,
            type: null,
            track: "",
            message: ""
        };

        this.state = {
            ...this.initialState
        };

        this.timeout = null;
    }

	handleKeydown = event => {
	    event.preventDefault();

	    const { key, keyCode } = event;
	    const { buffer: oBuffer, inputLen, statusID } = this.state;
	    const buffer = `${oBuffer}${key}`;
	    const len = buffer.length || 0;
	    const ignoreKeys = [ 9, 16, 17, 18, 20, 91, 93 ];

	    if (ignoreKeys.includes(keyCode)) return;

	    if (statusID === states.PENDING || statusID === states.ERROR || statusID === states.PROCESSING) {
	        return false;
	    }

	    if (statusID === states.READY) {
	        this.setState({
	            statusID: states.READING,
	            progress: 1,
	            inputLen: 0
	        });
	    }
		
	    if (inputLen === 0 && len > 60){
	        if (/^%B\d{16,19}\^.+\^*/.test(buffer)){
	            /* Swiped Input: General Swipe */
	            this.setState({ inputLen: 120 });
	        }
	        else if (/%[B*]/.test(buffer)) {
	            /* Swiped Input: IDTECH Augusta S & IDTECH SREDKey */
	            this.setState({ inputLen: 430 });
	        }
	        else if (/\?\*/.test(buffer)) {
	            /* Keyed Input: IDTECH SREDKey */
	            this.setState({ inputLen: 180 });

	        }
	        else if (/<\DvcMsg/.test(buffer)) {
	            /* Swiped & Keyed Input:  IDTECH M-130 */
	            this.setState({ inputLen: 400 });
	        }
	        else {
	            /* EMV Input:  IDTECH Augusta S */
	            this.setState({ inputLen: 1250 });
	        }
	    }

	    if (inputLen !== 0) {
	        const val = len * 100 / inputLen;
	        this.setState({ progress: Math.min(val, 100) });
	    }

	    this.setState({ buffer });

	    clearTimeout(this.timeout);
	    this.timeout = setTimeout(() => {
	        if (this.postProcess()) {
	            const { buffer } = this.state;
	            this.props.handleFieldChange({ name: "track", value: buffer });
	        }
	    }, 300);
	}

	postProcess = () => {
	    const { buffer } = this.state;
	    let message = "";

	    this.setState({ statusID: states.PROCESSING });

	    /* IDTECH Augusta : EMV test for EMV data (you may need to adjust the regex if the payload changes) */
	    const emv = /^[0-9A-Fa-f]+?$/.test(buffer);
	    if (emv === true && buffer.length > 900) {
	        this.setState({
	            progress: 100,
	            type: "emv"
	        });
	        this.setClose();
	        return true;
	    }

	    /* IDTECH Augusta/SREDKey : SWIPE */
	    const general = /^%B\d{16,19}\^.+\^*/.test(buffer);
	    if (general === true && buffer.length > 100) {

	        const [track1] = buffer.split("?");
	        let [card] = track1.split("^");
	        // const [last, first] = name.trim().split("/");
	        // const year = rest.slice(0, 2);
	        // const month = rest.slice(2, 4);
	        // const exp = `${month}/${year}`;
	        // name = `${first} ${last}`;
	        card = card.replace("%B", "");
			
	        this.props.handleFieldChange({ name: "track", value: buffer.current });
	        // this.props.handleFieldChange({ name: "exp", value: exp });
	        // this.props.handleFieldChange({ name: "cardNumber", value: card });
	        // this.props.handleFieldChange({ name: "name", value: name });

	        this.setState({
	            progress: 100,
	            type: "swipe",
	            message: `(***********${card.slice(-4)})`
	        });
	        this.setClose();

	        return true;
	    }

	    /* IDTECH Augusta/SREDKey : SWIPE */
	    const swipe = /%[B*]([0-9* ]{13,19})\^([A-Z ]+)\/([A-Z ]+).*/.test(buffer);
	    if (swipe === true && buffer.length > 350) {
	        const [, card, fname, lname] = /%[B*]([0-9* ]{13,19})\^([A-Z ]+)\/([A-Z ]+).*/.exec(buffer);
	        if (card !== undefined && fname !== undefined && lname !== undefined) {
	            message = `${fname} ${lname} (${card})`;
	        }
	        this.setState({
	            progress: 100,
	            type: "swipe",
	            message
	        });
	        this.setClose();
	        return true;
	    }

	    /* IDTECH SREDKey KEYED */
	    const keyed = /;([0-9* ]{13,19})=([0-9]{2})([0-9]{2})/.test(buffer);
	    if (keyed === true && buffer.length > 100) {

	        const [, card, yy, mm] = /;([0-9* ]{13,19})=([0-9]{2})([0-9]{2})/.exec(buffer);
	        if (card !== undefined && yy !== undefined && mm !== undefined) {
	            message = `${card} ${mm}/${yy}`;
	        }
	        this.setState({
	            progress: 100,
	            type: "keyed",
	            message
	        });
	        this.setClose();
	        return true;
	    }

		 /* IDTECH M-130 SWIPED/KEYED - test for legacy M130 by finding the terminating tag </DvcMsg> */
	    const legacy = /<\/DvcMsg>/.test(buffer);
	    if (legacy === true) {

	        const parser = new DOMParser();
	        const bufferDOM = parser.parseFromString(buffer, "text/html");
	        const entrytype = bufferDOM.querySelector("Dvc") ? bufferDOM.querySelector("Dvc").getAttribute("Entry") : "";

	        let cardholder = "";
	        let maskpan = "";

	        if (entrytype === "SWIPE") {
	            cardholder = bufferDOM.querySelector("Card") ? bufferDOM.querySelector("Card").getAttribute("CHolder") : "";
	            maskpan = bufferDOM.querySelector("Card") ? bufferDOM.querySelector("Card").getAttribute("MskPAN") : "";
	        }

	        if (entrytype === "MANUAL") {
	            cardholder = bufferDOM.querySelector("Card") ? bufferDOM.querySelector("Card").getAttribute("CHolder") : "";
	            maskpan = bufferDOM.querySelector("Card") ? bufferDOM.querySelector("Card").getAttribute("MskPAN") : "";
	        }

	        message = `${cardholder} (${maskpan})`;

	        this.setState({
	            progress: 100,
	            type: entrytype,
	            message
	        });
	        this.setClose();
	        return true;
	    }
	    
	    this.setState({ statusID: states.ERROR });
	    setTimeout(() => {
	        if (this.state.open){
	            this.setState({
	                ...this.initialState,
	                open: true
	            });
	        }
	    }, 2000);

	    return false;
	}

    setOpen = () => {
        this.addListener();
        this.setState({ ...this.initialState, open: true });
    }
	
    setClose = () => {
        this.removeListener();
        this.setState({ open: false });
    }

    addListener = () => {
        window.addEventListener("keydown", this.handleKeydown);
    }
	
    removeListener = () => {
        window.removeEventListener("keydown", this.handleKeydown);
    }

    render = () => {
        const { progress, statusID, open, message } = this.state;
        const { classes, error } = this.props;
        return (
            <React.Fragment>
                <Box display="flex" flexWrap="nowrap">
                    <Button onClick={this.setOpen} color="secondary" variant="contained">{this.props.button}</Button>
                    {message ? <Box paddingLeft={2} whiteSpace="break-word">{message}</Box> : null}
                </Box>
                {error ? <FormHelperText error>Card swipe is required</FormHelperText> : ""}
                <Dialog
                    fullWidth
                    maxWidth="xs"
                    open={open}
                    onClose={this.setClose}
                    disableBackdropClick
                >
                    <DialogTitle disableTypography>
                        <Typography variant="h2" style={{ textAlign: "center" }}>{this.props.title}</Typography>
                    </DialogTitle>
                    <DialogContent style={{ paddingTop: 0 }}>
                        <Box textAlign="center" height={120}>
                            <CircularProgressWithLabel value={progress} size={75} thickness={5} />
                            <Typography variant="h4" className={statusID === states.ERROR ? classes.error : null}>
                                {statusLabels[statusID]}
                            </Typography>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.setClose} color="secondary">
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}

SwipeCardField.propTypes = {
    button: PropTypes.string,
    title: PropTypes.string
};

SwipeCardField.defaultProps = {
    button: "Swipe Card",
    title: "Card Reader"
};

const mapStateToProps = state => ({
    error: state.form.errors?.track
});

const mapDispatchToProps = { handleFieldChange };

export default connect(mapStateToProps, mapDispatchToProps)(
    withStyles(styles)(SwipeCardField)
);
