import { useRef } from "react";
/**
 *
 * @param {Function} callback
 * @param {Number} delay
 * @returns Debounced Function
 */
function useDebounce(callback, delay) {
    if (typeof callback !== "function"){
        throw Error("useDebounce callback argument must be a function");
    }
	
    const timeout = useRef();

    return (e, ...args) => {
        e.persist();
        clearTimeout(timeout.current);
        timeout.current = setTimeout(() => callback(e, ...args), delay || 0);
    };
}

export default useDebounce;
