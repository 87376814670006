import React from "react";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";
import { InputAdornment, TextField } from "@material-ui/core";
import { isNumeric } from "../../../helpers/utils";

const Dollar = ({ name, label, value, helperText, error, ...allOtherProps }) => {
    const {
        adornmentProps = {},
        autoComplete,
        autoFocus,
        classes,
        className,
        color,
        defaultValue,
        disabled,
        FormHelperTextProps,
        fullWidth = true,
        InputLabelProps,
        InputProps = {},
        inputProps,
        inputRef,
        margin,
        onChange,
        onBlur,
        onFocus,
        onKeyDown,
        placeholder,
        required,
        size,
        useNegative = false,
        variant = "outlined",
        inputComponent = NumberFormatCustom
    } = allOtherProps;

    const eligibleProps = {
        autoComplete,
        autoFocus,
        classes,
        className,
        color,
        defaultValue,
        disabled,
        FormHelperTextProps,
        fullWidth,
        InputLabelProps,
        InputProps,
        inputProps,
        inputRef,
        margin,
        onChange,
        onBlur,
        onKeyDown,
        placeholder,
        required,
        size,
        variant
    };
	
    return (
        <TextField
            {...eligibleProps}
            id={name}
            label={label}
            value={isNumeric(value) ? value : ""}
            type="text"
            helperText={`${error || ""}${error && helperText ? " - " : ""}${helperText || ""}`}
            error={Boolean(error)}
            InputProps={{
                startAdornment: value ? <InputAdornment position="start" {...adornmentProps}>{useNegative ? "-" : ""}$</InputAdornment> : null,
                inputComponent: inputComponent,
                onFocus,
                ...InputProps
            }}
        />
    );
};

Dollar.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;
    return (
        <NumberFormat {...other}
            getInputRef={inputRef}
            thousandSeparator
            isNumericString
            decimalScale={2}
            fixedDecimalScale
            onValueChange={values => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value
                    }
                });
            }}
        />
    );
}

export default React.memo(Dollar);
