import { round } from "./utils";

export const isInPOSCart = (product, variantIDs, cartItems) => {
    return cartItems.find(item => {
        if (item.productID === product.productID){
            if (!item.sizeID && !product.sizeID && !item.variantIDs?.length && !variantIDs?.length){
                /* no sizes, no variations, matching product IDs */
                return true;
            }
            if (item.sizeID === product.sizeID && !item.variantIDs?.length && !variantIDs?.length) {
                /* matching sizes, no variations, matching product IDs */
                return true;
            }
            if (item.sizeID === product.sizeID && item.variantIDs?.length === variantIDs?.length){
                /* matching sizes, matching variations, matching product IDs */
                return variantIDs.every(productVariation => item.variantIDs.some(variation => variation.variantID === productVariation.variantID));
            }
        }
        return false;
    });
};

export const updateCartTotals = (cartItems, taxesToApply, discounts) => {
    let amount = 0;
    let taxAmount = 0;

    amount = cartItems.reduce((acc, item) => {
        return acc + item.quantity * (item.price + (item.totalPriceChange ? item.totalPriceChange : 0));
    }, 0);

    const totalTaxes = taxesToApply.reduce((acc, obj) => {
        if (!obj.isShipping) {
            acc.percent += obj.taxRate;
            acc.fee += obj.taxFee;
        }
        return acc;
    }, { percent: 0, fee: 0 });

    const taxableItems = cartItems.filter(item => item.taxable);

    let totalTaxablePrice = taxableItems.reduce((acc, obj) => {
        return acc + (Number(obj.price) + Number(obj.totalPriceChange ? obj.totalPriceChange : 0)) * obj.quantity;
    }, 0);

    if (totalTaxablePrice && discounts.priceDiscount) {
        if (totalTaxablePrice - discounts.priceDiscount < 0) {
            totalTaxablePrice = 0;
        }
        else {
            totalTaxablePrice -= discounts.priceDiscount;
        }
    }
    else if (totalTaxablePrice && discounts.percentDiscount) {
        if (totalTaxablePrice - totalTaxablePrice * discounts.percentDiscount < 0) {
            totalTaxablePrice = 0;
        }
        else {
            totalTaxablePrice = totalTaxablePrice - totalTaxablePrice * discounts.percentDiscount;
        }
    }
    const totalTaxPrice = totalTaxablePrice * (totalTaxes.percent / 100) + totalTaxes.fee;
    taxAmount = round(totalTaxPrice);

    return { amount, taxAmount, priceDiscount: discounts.priceDiscount ? discounts.priceDiscount : null, percentDiscount: discounts.percentDiscount ? discounts.percentDiscount : null };
};

export const parseVariantID = (data, allVariationGroups) => {
    if (!data) return [];
    const variations = [];
    const keys = Object.keys(data).filter(key => key === "variationIDs" || key.includes("variation_"));
    for (const key of keys) {
        if (key === "variationIDs") {
            Object.values(data[key]).forEach(variationID => {
                allVariationGroups.forEach(group => {
                    const variation = group.variations.find(v => v.variantID === Number(variationID));
                    if (variation) {
                        variations.push(variation);
                        return;
                    }
                });
            });
        }
        else if (data[key]) {
            allVariationGroups.forEach(group => {
                const variationID = Number(key.match(/\d+/)[0]);
                let variation = group.variations.find(v => v.variantID === variationID);
                if (variation) {
                    variation = { ...variation };
                    if (key.startsWith("half_")) {
                        variation.halfSelected = true;
                        if (variation.priceChange !== 0) variation.priceChange /= 2;
                    }
                    variations.push(variation);
                    return;
                }
            });
        }
    }
    return variations;
};

export const calculateCashReportTotal = ({ subtotal, taxAmount, tipAmount = 0, priceDiscount = 0, percentDiscount = 0 }) => {
    let total = subtotal;
    if (priceDiscount > 0) {
        total = subtotal - priceDiscount;
    }
    else if (percentDiscount > 0) {
        total = subtotal - (subtotal * percentDiscount);
    }
    total += taxAmount + tipAmount;
    return total;
};

const posHelpers = {
    isInPOSCart,
    updateCartTotals,
    parseVariantID,
    calculateCashReportTotal
};

export default posHelpers;
