import getStorageItem from "../helpers/getStorageItem";
import {
    DELETE_START,
    DELETE_SUCCESS,
    FILTER_ALL_RESET,
    FILTER_HIDE,
    FILTER_SHOW,
    FILTER_VALUE_SET,
    GET_LIST_FAIL,
    GET_LIST_START,
    GET_LIST_SUCCESS,
    INIT_LIST,
    QUICK_SEARCH_SET,
    SET_GRID_FILTERS,
    TRIGGER_GRID_REFRESH,
    UPDATE_LIST_PROP
} from "../actions/types";

const initialState = {
    name: "",
    apiRoute: "",
    records: [],
    response: {},
    totalRecords: 0,
    fetching: false,
    refreshTriggered: false,
    showFilters: false,
    filters: [],
    filterMemory: {},
    quickSearchValue: "",
    order: "asc",
    orderBy: "",
    page: 0,
    rowsPerPage: Number(getStorageItem("rowsPerPage")) || 20
};

const listReducer = function(state = initialState, action) {
    switch (action.type) {
        case DELETE_START:
            return {
                ...state,
                refreshTriggered: false,
                fetching: true
            };
        case FILTER_SHOW:
            return {
                ...state,
                showFilters: true
            };
        case FILTER_HIDE:
            return {
                ...state,
                showFilters: false
            };
        case FILTER_VALUE_SET: {
            const { name, value, operator } = action.payload;
            const filters = state.filters.map(f => {
                if (f.name === name){
                    f.filterOps = {
                        ...f.filterOps || {},
                        [operator]: value
                    };
                }
                return f;
            });
            return {
                ...state,
                filters,
                filterMemory: setFilterMemory(state, filters, "filters")
            };
        }
        case FILTER_ALL_RESET: {
            const filters = state.filters.map(filter => ({
                ...filter,
                filterOps: {}
            }));
            return {
                ...state,
                filters,
                filterMemory: setFilterMemory(state, filters, "filters")
            };
        }
        case INIT_LIST: {
            if (action.payload.apiRoute){
                /* if this grid is in memory, return that version of filters */
                if (state.filterMemory[action.payload.apiRoute]){
                    return {
                        ...state,
                        ...action.payload,
                        ...state.filterMemory[action.payload.apiRoute],
                        records: []
                    };
                }
                return {
                    ...initialState,
                    ...action.payload,
                    filterMemory: { ...state.filterMemory },
                    page: 0,
                    quickSearchValue: "",
                    records: []
                };
            }
            return state;
        }
        case SET_GRID_FILTERS: {
            if (action.payload.apiRoute) {
                const memo = state.filterMemory[action.payload.apiRoute];
                if (memo && Array.isArray(memo.filters) && memo.filters.length){
                    return {
                        ...state,
                        ...action.payload,
                        filters: memo.filters
                    };
                }
            }
            return {
                ...state,
                filters: action.payload.filters
            };
        }
        case GET_LIST_START:
            return {
                ...state,
                name: action.payload.name,
                fetching: true
            };
        case GET_LIST_SUCCESS: {
            const { page, rowsPerPage } = state;
            
            let records;
            let restOfData = {};

            if (Array.isArray(action.payload)) records = action.payload;
            else if ("records" in action.payload) {
                let { records: recs, ...rest } = action.payload;
                records = recs;
                restOfData = rest;
            }

            const [firstRec] = records || [];
            const totalRecords = firstRec && firstRec.totalRecords ? firstRec.totalRecords : records.length;

            return {
                ...state,
                ...restOfData,
                records,
                totalRecords,
                refreshTriggered: false,
                fetching: false,
                page: page < Math.floor((totalRecords + rowsPerPage - 1) / rowsPerPage) ? page : 0
            };
        }
        case GET_LIST_FAIL:
            return {
                ...state,
                fetching: false,
                refreshTriggered: false,
                records: [],
                totalRecords: 0
            };
        case QUICK_SEARCH_SET:
            return {
                ...state,
                quickSearchValue: action.payload,
                filterMemory: setFilterMemory(state, action.payload, "quickSearchValue")
            };
        case DELETE_SUCCESS:
        case TRIGGER_GRID_REFRESH:
            return {
                ...state,
                refreshTriggered: true,
                response: action.payload || {},
                fetching: false
            };
        case UPDATE_LIST_PROP:
            return {
                ...state,
                ...action.payload,
                filterMemory: setFilterMemory(state, action.payload)
            };
        default:
            return state;
    }
};

/**
 * Function is used to copy filter changes into memory on a list by list basis.
 * This preserves filters so the list won't reset when you navigate to another page
 */
const setFilterMemory = function(state, value, key){
    if (state.apiRoute){
        let newCopy = {
            quickSearchValue: state.quickSearchValue,
            order: state.order,
            orderBy: state.orderBy,
            page: state.page,
            rowsPerPage: state.rowsPerPage,
            ...(state.filterMemory[state.apiRoute] || {})
        };
        if (key) {
            newCopy[key] = value;
        }
        else {
            newCopy = { ...newCopy, ...value };
        }
        return { ...state.filterMemory, [state.apiRoute]: newCopy };
    }
    return { ...state.filterMemory };
};

export default listReducer;
