import React from "react";
import TextField from "@material-ui/core/TextField";
import ChromePicker from "react-color/lib/Chrome";
import { ClickAwayListener, Popper } from "@material-ui/core";
import ColorPreview from "../../ColorPreview";

const ColorPicker = ({ name, label, value, helperText, error, onChange, ...props }) => {
    const {
        classes,
        className,
        disabled,
        placeholder,
        fullWidth = true,
        type = "text",
        variant = "outlined"
    } = props;

    const eligibleProps = { classes, className, disabled, fullWidth, placeholder, type, variant };

    const [show, setShow] = React.useState(null);
    const handleClick = e => {
        if (disabled) return;
        setShow(e.target);
    };

    const handleKeyDown = e => {
        if (e.keyCode === 27) {
            setShow(null);
            onChange({ hex: "" });
        }
    };

    return (
        <React.Fragment>
            <TextField
                {...eligibleProps}
                fullWidth
                name={name}
                label={label}
                value={value || ""}
                onClick={handleClick}
                onKeyDown={handleKeyDown}
                inputProps={{ autoComplete: "off" }}
                InputProps={{
                    startAdornment: <ColorPreview height={30} color={value} showCode={false} border="1px solid rgba(0, 0, 0, 0.23)" />
                }}
                helperText={`${error || ""}${error && helperText ? " - " : ""}${helperText || ""}${helperText ? ", " : ""}Press esc key to clear color.`}
                error={Boolean(error)}
            />
            <Popper open={show !== null} anchorEl={show} placement="bottom-start" style={{ zIndex: 5 }} >
                <ClickAwayListener onClickAway={() => setShow(null)}>
                    <ChromePicker color={value || "#fff"} onChange={onChange} />
                </ClickAwayListener>
            </Popper>
        </React.Fragment>
    );
};

export default React.memo(ColorPicker);
