import { DRAG_EVENT, DRAG_REORDER_HIDE, DRAG_REORDER_SHOW, DRAG_SAVE_START, DRAG_SAVE_SUCCESS, DRAG_SET_LIST } from "../actions/types";

const initialState = {
    reorderDrawerOpen: false,
    sortedRecs: [],
    titleOfOpenDrawer: ""
};

const dragReducer = function(state = initialState, action) {
    switch (action.type) {
        case DRAG_SET_LIST:
            return {
                ...state,
                sortedRecs: action.payload.list
            };
        case DRAG_REORDER_SHOW:
            return {
                ...state,
                reorderDrawerOpen: true,
                titleOfOpenDrawer: action.payload
            };
        case DRAG_REORDER_HIDE:
            return {
                ...initialState
            };
        case DRAG_SAVE_START:
            return {
                ...state,
                fetching: true
            };
        case DRAG_SAVE_SUCCESS:
            return {
                ...state,
                fetching: false,
                response: action.payload
            };
        case DRAG_EVENT:{
            const { name, source, destination } = action.payload;
            if (destination.index === source.index) {
                return state;
            }
            if (name === "REORDER_DRAWER") {
                return {
                    ...state,
                    sortedRecs: reorder(state.sortedRecs, source.index, destination.index)
                };
            }
            return state;
        }
        default:
            return state;
    }
};

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result.map((r, i) => ({ ...r, dragIndex: i }));
};

export default dragReducer;
