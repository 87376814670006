import getStorageItem from "../helpers/getStorageItem";
import {
    CHANGE_SERVICEGROUP_SUCCESS,
    CLEAR_ENDPOINT,
    END_POINT_FAIL,
    END_POINT_START,
    END_POINT_SUCCESS,
    GLOBAL_DIALOG_HIDE,
    GLOBAL_DIALOG_SHOW,
    LOGOUT_SUCCESS,
    NOTIFICATION_REMOVE_ALL,
    NOTIFICATION_REMOVED,
    NOTIFICATION_VIEW_ALL,
    NOTIFICATION_VIEWED,
    NOTIFICATIONS_HIDE,
    NOTIFICATIONS_SHOW,
    NOTIFICATIONS_SUCCESS,
    PRINT_DONE,
    PRINT_START,
    RECENT_ACTIVITY_HIDE,
    RECENT_ACTIVITY_SHOW,
    RECENT_ACTIVITY_SUCCESS,
    REFRESH_TOKEN_FAIL,
    SNACK_BAR_ADD,
    SNACK_BAR_PROCESS,
    TOGGLE_NAV
} from "../actions/types";

const initialState = {
    drawerWidth: 240,
    navOpen: Boolean(getStorageItem("navOpen")),
    dialog: null,
    printing: false,
    endpoints: {},
    endpointLoading: false,
    endpointLoaders: {},
    snacks: [],
    showNotifications: false,
    notifications: [],
    recentActivity: [],
    showActivity: false,
    messageActive: {},
    messages: [

    ]
};

const layoutReducer = function(state = initialState, action) {
    switch (action.type) {
        case TOGGLE_NAV:
            return {
                ...state,
                navOpen: !state.navOpen
            };
        case GLOBAL_DIALOG_SHOW:
            return {
                ...state,
                dialog: action.payload
            };
        case GLOBAL_DIALOG_HIDE:
            return {
                ...state,
                dialog: null
            };
        case END_POINT_START:
            return {
                ...state,
                endpointLoading: true,
                endpointLoaders: {
                    ...state.endpointLoaders,
                    [action.payload.key]: true
                }
            };
        case END_POINT_SUCCESS:
            return {
                ...state,
                endpointLoading: false,
                endpoints: {
                    ...state.endpoints,
                    [action.payload.key]: action.payload.data
                },
                endpointLoaders: {
                    ...state.endpointLoaders,
                    [action.payload.key]: false
                }
            };
        case END_POINT_FAIL:
            return {
                ...state,
                endpointLoading: false,
                endpointLoaders: {
                    ...state.endpointLoaders,
                    [action.payload.key]: false
                }
            };
        case SNACK_BAR_ADD:
            return {
                ...state,
                snacks: [...state.snacks, { message: action.payload.snack, action: action.payload.action, key: new Date().getTime() }]
            };
        case SNACK_BAR_PROCESS: {
            const [, ...remaining] = state.snacks;
            return {
                ...state,
                snacks: [...remaining]
            };
        }
        case NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                notifications: action.payload
            };
        case NOTIFICATION_VIEWED:{
            const notifications = state.notifications.map(n => {
                if (n.notificationID === action.payload){
                    n.viewed = new Date();
                }
                return n;
            });
            return {
                ...state,
                notifications
            };
        }
        case NOTIFICATION_VIEW_ALL: {
            const notifications = state.notifications.map(n => ({ ...n, viewed: new Date() }));
            return {
                ...state,
                notifications
            };
        }
        case NOTIFICATION_REMOVED: {
            const notifications = state.notifications.filter(n => n.notificationID !== action.payload);
            return {
                ...state,
                notifications
            };
        }
        case NOTIFICATION_REMOVE_ALL: {
            return {
                ...state,
                notifications: []
            };
        }
        case NOTIFICATIONS_SHOW:
            return {
                ...state,
                showNotifications: true
            };
        case NOTIFICATIONS_HIDE:
            return {
                ...state,
                showNotifications: false
            };
        case PRINT_START: {
            return {
                ...state,
                printing: true
            };
        }
        case PRINT_DONE: {
            return {
                ...state,
                printing: false
            };
        }
        case RECENT_ACTIVITY_HIDE:
            return {
                ...state,
                showActivity: false
            };
        case RECENT_ACTIVITY_SHOW:
            return {
                ...state,
                showActivity: true
            };
        case RECENT_ACTIVITY_SUCCESS:
            return {
                ...state,
                recentActivity: action.payload
            };
        case CLEAR_ENDPOINT:{
            const { [action.payload]: removedEndpoint, ...restEndpoints } = state.endpoints; // eslint-disable-line no-unused-vars
            const { [action.payload]: removedLoader, ...restLoaders } = state.endpointLoaders; // eslint-disable-line no-unused-vars
            return {
                ...state,
                endpoints: restEndpoints,
                endpointLoaders: restLoaders
            };
        }
        case LOGOUT_SUCCESS:
        case REFRESH_TOKEN_FAIL:
        case CHANGE_SERVICEGROUP_SUCCESS:
            return initialState;
        default:
            return state;
    }
};

export default layoutReducer;
