import React from "react";
import PropTypes from "prop-types";
import { Box, IconButton, InputBase, makeStyles, Paper } from "@material-ui/core";
import { fade } from "@material-ui/core/styles/colorManipulator";
import { Close, Search } from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
    search: props => {
        const background = props.dark ? theme.palette.secondary.main : theme.palette.common.white;
        return {
            position: "relative",
            backgroundColor: fade(background, 0.15),
            "&:hover": {
                backgroundColor: fade(background, 0.25)
            },
            marginLeft: 0,
            width: "auto%"
        };
    },
    searchIcon: {
        padding: theme.spacing(0, 1),
        height: "100%",
        position: "absolute",
        pointerEvents: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    inputRoot: {
        color: "inherit"
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        paddingRight: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create("width"),
        width: "100%"
    },
    clearBtnContainer: {
        padding: theme.spacing(0, 0, 0, 2),
        height: "100%",
        position: "absolute",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        top: 0,
        right: 0
    },
    clearBtn: {
        marginRight: theme.spacing(.5)
    }
}));
  
const SearchBox = ({ onChange, onClear, value, placeholder = "Search...", square = false, elevation = 0, padding = .5, gutterBottom, dark = false }) => {
    const classes = useStyles({ dark });
    return (
        <Box component={Paper} square={square} elevation={elevation} padding={padding} marginBottom={gutterBottom ? 1 : undefined}>
            <div className={classes.search}>
                <div className={classes.searchIcon}>
                    <Search />
                </div>
                <InputBase
                    placeholder={placeholder}
                    fullWidth
                    value={value || ""}
                    onChange={onChange}
                    classes={{
                        root: classes.inputRoot,
                        input: classes.inputInput
                    }}
                    inputProps={{ "aria-label": "search" }}
                />
                <div className={classes.clearBtnContainer}>
                    <IconButton className={classes.clearBtn} onClick={onClear}>
                        <Close />
                    </IconButton>
                </div>
            </div>
        </Box>
    );
};

SearchBox.propTypes = {
    onChange: PropTypes.func.isRequired,
    onClear: PropTypes.func.isRequired
};

export default React.memo(SearchBox);
