import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { FormControl, FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    formControl: {
        margin: 0,
        padding: 0,
        flex: 1
    },
    formControlLabel: {
        flex: 1
    },
    radioGroup: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "nowrap",
        "& .hiddenRadio": {
            display: "none"
        }
    },
    radioButton: {
        border: `1px solid rgba(0, 0, 0, 0.23)`,
        padding: theme.spacing(1.6, 2),
        cursor: "pointer",
        textAlign: "center",
        "&.selected": {
            backgroundColor: theme.palette.primary.main,
            color: "#fff"
        },
        "&:not(.selected)": {
            backgroundColor: "#fff",
            color: "#000"
        },
        "&:hover": {
            borderColor: theme.palette.secondary.main
        },
        "&.error": {
            borderColor: theme.palette.error.main,
            color: theme.palette.error.main
        }
    }
}));

const RadioButtonGroup = ({ name, label, options, value, error, helperText, onChange }) => {
    const classes = useStyles();
    const hasError = Boolean(error);
    return (
        <FormControl fullWidth error={hasError} variant="outlined">
            {label ? <FormLabel error={hasError}>{label}</FormLabel> : null}
            <RadioGroup
                className={classes.radioGroup}
                name={name}
                value={value}
                onChange={onChange}
            >
                {options.map(option => (
                    <FormControlLabel
                        key={option.value}
                        value={option.value}
                        classes={{ label: classes.formControlLabel, root: classes.formControl }}
                        control={<Radio className="hiddenRadio" />}
                        label={
                            <div className={`${classes.radioButton} ${value === option.value ? "selected" : ""} ${error ? "error" : ""}`}>
                                {option.label}
                            </div>
                        }
                    />
                ))}
            </RadioGroup>
            {error || helperText ? <FormHelperText>{`${error || ""}${error && helperText ? " - " : ""}${helperText || ""}`}</FormHelperText> : null}
        </FormControl>
    );
};

RadioButtonGroup.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
    })).isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    helperText: PropTypes.string,
    onChange: PropTypes.func.isRequired
};

RadioButtonGroup.defaultProps = {
    value: ""
};

export default React.memo(RadioButtonGroup);
