import { MONTHS, WEEKDAYS } from "../common/consts";

export const formatNumber = number => new Intl.NumberFormat().format(number || 0);
export const formatDollar = (number, decimals = 2, signDisplay = "auto") =>
    new Intl.NumberFormat("default", { style: "currency", currency: "USD", minimumFractionDigits: decimals, maximumFractionDigits: decimals, signDisplay }).format(number || 0);
export const formatDecimal = (number, decimals = 2) => new Intl.NumberFormat("default", { style: "decimal", minimumFractionDigits: decimals, maximumFractionDigits: decimals }).format(number || 0);
export const formatPercent = (number, decimals = 2) => new Intl.NumberFormat("default", { style: "percent", minimumFractionDigits: decimals, maximumFractionDigits: decimals }).format(number / 100 || 0);
export const paddedNumber = (number, leadingDigits = 0) => new Intl.NumberFormat("default", { minimumSignificantDigits: leadingDigits }).format(number || 0);

export const formatGigabyte = size => `${formatDecimal((Number(size) / 1000000000), 2)} GB`;
export const formatMegabyte = size => `${formatDecimal((Number(size) / 1000000), 1)} MB`;
export const formatKilobyte = size => `${formatDecimal((size / 1000), 1)} KB`;

export const formatFileSize = size => {
    if (!size) return "0 KB";
    if (size > 100000000) return formatGigabyte(size);
    if (size > 1000000) return formatMegabyte(size);
    return formatKilobyte(size);
};

export const formatChartValue = val => {
    val = Number(val);
    if (val > 1000000) return `$${(val / 1000000).toFixed(1)}M`;
    if (val > 100000) return `$${(val / 1000).toFixed(0)}K`;
    if (val > 1000) return `$${(val / 1000).toFixed(1)}K`;
    return `$${val.toFixed(0)}`;
};

export const formatDate = date => {
    if (!date) return date;
    const { MM, DD, YY } = getDateParts(date);
    return `${MM}/${DD}/${YY}`;
};

export const formatDateISO = date => {
    if (!date) return date;
    const { MM, DD, YY } = getDateParts(date);
    return `${YY}-${MM}-${DD}`;
};

export const formatDatetimeISO = date => {
    if (!date) return date;
    const { MM, DD, YY, HH, mm, ss } = getDateParts(date);
    return `${YY}-${MM}-${DD}T${HH}:${mm}:${ss}`;
};

export const formatDatetime = (date, use24Hour = false) => {
    if (!date) return date;
    const { MM, DD, YY, HH, hh, mm, ss, pp } = getDateParts(date);
    return `${MM}/${DD}/${YY} ${use24Hour ? HH : hh}:${mm}:${ss} ${use24Hour ? "" : pp}`;
};

export const formatTime = (date, use24Hour = false) => {
    if (!date) return date;
    const { hh, HH, mm, pp } = getDateParts(date);
    return `${Number(use24Hour ? HH : hh)}:${mm} ${use24Hour ? "" : pp}`;
};

export const formatFullDateAndTime = (date, time, use24Hour = false) => {
    const { DD, YY, WWWW, MMMM } = getDateParts(date);
    let rtn = `${WWWW}, ${MMMM} ${formatOrdinal(DD)}, ${YY}`;
    if (time) {
        if (typeof time === "string") time = new Date(time);
        const { hh, HH, mm, PP } = getDateParts(time);
        rtn = `${rtn} at ${Number(use24Hour ? HH : hh)}:${mm}${use24Hour ? "" : PP}`;
    }
    return rtn;
};

export const formatOrdinal = function(number) {
    let s = ["th", "st", "nd", "rd"],
        v = number % 100;
    return Number(number) + (s[(v - 20) % 10] || s[v] || s[0]);
};

const formatDateRange = (sdate, edate) => {
    if (sdate && edate){
        return `${formatDate(sdate)} - ${formatDate(edate)}`;
    }
    if (sdate){
        return `After ${formatDate(sdate)}`;
    }
    if (edate){
        return `Before ${formatDate(edate)}`;
    }
    return "n/a";
};

const parseDate = str => {
    /* if date object, convert back to string */
    if (str.toJSON) str = str.toJSON();
    if (/\b\d\d\d\d\d\d\d\d\b/.test(str)){
        const [d1, d2, d3, d4, d5, d6, d7, d8] = str.split("");
        return `${d1}${d2}-${d3}${d4}-${d5}${d6}${d7}${d8}T00:00:00`;
    }
    if (/\b\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z\b/.test(str)){
        const [yyyy, MM, dd, HH, mm, ss] = str.split(/[-T:Z.]/);
        return `${yyyy}-${MM}-${dd}T${HH}:${mm}:${ss}`;
    }
    if (/\b\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z\b/.test(str)){
        const [yyyy, MM, dd, HH, mm, ss] = str.split(/[-T:Z.]/);
        return `${yyyy}-${MM}-${dd}T${HH}:${mm}:${ss}`;
    }
    if (/\b\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\b/.test(str)){
        const [yyyy, MM, dd, HH, mm, ss] = str.split(/[-T:Z.]/);
        return `${yyyy}-${MM}-${dd}T${HH}:${mm}:${ss}`;
    }
    if (/\b\d\d\d\d-\d\d-\d\d\b/.test(str)) {
        return `${str}T00:00:00`;
    }
    return str;
};

export const getDateParts = str => {
    let dte = parseDate(str);
    if (!dte || isNaN(new Date(dte).getTime())) return "";

    dte = new Date(dte);
    const YY = dte.getFullYear(); // 4-digit year
    const MM = padNumber(dte.getMonth() + 1);
    const DD = padNumber(dte.getDate());
    const mm = padNumber(dte.getMinutes());
    const HH = padNumber(dte.getHours()); // 24-hour format
    const hh = padNumber(HH === "00" ? 12 : HH > 12 ? HH - 12 : HH);
    const PP = HH > 11 ? "PM" : "AM";
    const pp = PP.toLowerCase();
    const ss = padNumber(dte.getSeconds());
    const ww = dte.getDay();
    const WWWW = WEEKDAYS[Number(ww)];
    const WWW = WWWW.slice(0, 3);
    const MMMM = MONTHS[Number(MM) - 1];
    const MMM = MMMM.slice(0, 3);

    return { YY, MM, DD, HH, hh, mm, MMM, MMMM, ss, ww, WWW, WWWW, PP, pp };
};

export const padNumber = (number, length = 2) => {
    if (length > 20 || length < 1) throw new Error("Invalid padding length, length required 1-20.");
    return `00000000000000000000${number}`.slice(length * -1);
};

export const formatPhone = value => {
    if (value) {
        value = value.replace(/[^\d]/g, "");

        if (value.length === 11 && parseInt(value.substring(0, 1), 10) === 1) {
            // Remove the US 1 code from the front of phone numbers.
            value = value.substring(1);
        }

        if (value.length === 10) {
            return value.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
        }

        if (value.length === 7) {
            return value.replace(/(\d{3})(\d{4})/, "$2-$3");
        }
    }
    return value;
};

export const formatGender = value => {
    if (value === "M") {
        return "Male";
    }

    if (value === "F") {
        return "Female";
    }

    return null;
};

export const formatBoolean = value => {
    return value == true ? "Yes" : "No"; //eslint-disable-line
};

const acronyms = {
    AEMT: 1,
    AHA: 1,
    AKA: 1,
    AMLS: 1,
    BLS: 1,
    BYOB: 1,
    CPR: 1,
    DOB: 1,
    EMR: 1,
    EMT: 1,
    ETA: 1,
    FAQ: 1,
    FYI: 1,
    HR: 1,
    ID: 1,
    IQ: 1,
    MD: 1,
    NA: 1,
    MIA: 1,
    PC: 1,
    POW: 1,
    RIP: 1,
    RSVP: 1
};

export const capitalizeFirstLetter = function(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

export const formatTitleCase = (value, force = false) => {
    if (!value || typeof value.includes === "undefined") return value;
    if (value && !value.includes(" ") && isUpperCase(value) && value.length <= 6 && !force) return value;
    value = String(value).split(" ");
    const words = value.length;
    for (let i = 0; i < words; i++) {
        if (!acronyms[value[i]]) {
            value[i] = value[i].charAt(0).toUpperCase() + value[i].slice(1).toLowerCase();
        }
    }
    return value.join(" ");
};

function isUpperCase(str) {
    return str === str?.toUpperCase();
}

export const formatTimeSince = minutes => {
    if (!minutes) return "less than 1 minute ago";
	
    if (minutes < 60) return `${minutes} ${minutes === 1 ? "minute" : "minutes"}  ago`;
	
    const hours = Math.floor(minutes / 60.0);
    if (hours < 24) return `${hours} ${hours === 1 ? "hour" : "hours"} ago`;
	
    const days = Math.floor(hours / 24.0);
    if (days < 7) return `${days} ${days === 1 ? "day" : "days"} ago`;
	
    const weeks = Math.floor(days / 7.0);
    if (weeks < 4.5) return `${weeks} ${weeks === 1 ? "week" : "weeks"} ago`;
	
    const months = weeks / 4.5;
    return `${Math.floor(months)} ${months === 1 ? "month" : "months"} ago`;
};

export const types = {
    BOOL: "BOOL",
    DATE: "DATE",
    DATEFULL: "DATEFULL",
    DATEISO: "DATEISO",
    DATERANGE: "DATERANGE",
    DATETIME: "DATETIME",
    DECIMAL: "DECIMAL",
    DOLLAR: "DOLLAR",
    FILESIZE: "FILESIZE",
    GENDER: "GENDER",
    NUMBER: "NUMBER",
    ORDINAL: "ORDINAL",
    PAD: "PAD",
    PERCENT: "PERCENT",
    PHONE: "PHONE",
    TIME: "TIME",
    TIMESINCE: "TIMESINCE",
    TITLE: "TITLE"
};

const format = (type, value, ...args) => {
    const { BOOL, DATE, DATEFULL, DATEISO, DATETIMEISO, DATERANGE, DATETIME, DECIMAL, DOLLAR, FILESIZE, GENDER, NUMBER, ORDINAL, PAD, PERCENT, PHONE, TIME, TIMESINCE, TITLE } = types;

    const formatters = {
        [BOOL]: formatBoolean,
        [DATE]: formatDate,
        [DATEFULL]: formatFullDateAndTime,
        [DATEISO]: formatDateISO,
        [DATERANGE]: formatDateRange,
        [DATETIME]: formatDatetime,
        [DATETIMEISO]: formatDatetimeISO,
        [DECIMAL]: formatDecimal,
        [DOLLAR]: formatDollar,
        [FILESIZE]: formatFileSize,
        [GENDER]: formatGender,
        [NUMBER]: formatNumber,
        [ORDINAL]: formatOrdinal,
        [PAD]: padNumber,
        [PERCENT]: formatPercent,
        [PHONE]: formatPhone,
        [TIME]: formatTime,
        [TIMESINCE]: formatTimeSince,
        [TITLE]: formatTitleCase
    };

    if (formatters[type]) {
        return formatters[type](value, ...args);
    }
    else {
        console.warn(`eServices format.js "${type}(${value})" function not found`);
        return value;
    }
};

export default format;
