// Credit David Walsh (https://davidwalsh.name/javascript-debounce-function)

// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
function debounce(func, wait, immediate) {
    let timeout;
    let clickCount = 0;
    return function executedFunction() {
        const context = this;
        const args = arguments;
        clickCount++;
        if (args[0]) args[0].clickCount = clickCount;
        const later = function () {
            timeout = null;
            if (!immediate) {
                func.apply(context, args);
                clickCount = 0;
            }
        };
        const callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) {
            func.apply(context, args);
            clickCount = 0;
        }
    };
}

export default debounce;
