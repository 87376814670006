import React from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

function useQueryParams() {
    const { search } = useLocation();
    return React.useMemo(() => queryString.parse(search), [search]);
}

export default useQueryParams;
