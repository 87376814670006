import React from "react";
import { connect } from "react-redux";
import Select from "./base/Select";
import { handleFieldChange } from "../../actions/formActions";

const SelectField = ({ name, handleFieldChange, optVal, onChange, options = [], value, useFirst, defValue, allowEmptyStringValues, ...allOtherProps }) => {
    React.useEffect(() => {
        if (!value) {
            if (defValue) {
                handleFieldChange({ name, value: defValue });
            }
            else if (useFirst && options.length) {
                handleFieldChange({ name, value: options[0][optVal] });
            }
        }
    }, [name, value, options, optVal, defValue, useFirst, handleFieldChange]);

    const handleChange = e => {
        handleFieldChange({ name, value: e.target.value });
        if (typeof onChange === "function") onChange(e);
    };
	
    return <Select {...allOtherProps} name={name} value={value} optVal={optVal} onChange={handleChange} options={options} allowEmptyStringValues={allowEmptyStringValues} />;
};

const mapsStateToProps = (state, { name, ...ownProps }) => {
    return {
        value: state.form.data[name] ? String(state.form.data[name]) : "",
        options: "options" in ownProps ? ownProps.options : state.form.dropdowns[name],
        error: state.form.errors[name]
    };
};

const mapDispatchToProps = { handleFieldChange };

export default connect(mapsStateToProps, mapDispatchToProps)(SelectField);
