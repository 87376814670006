import {
    CHANGE_SERVICEGROUP_SUCCESS,
    CLEAR_DETAIL_DATA,
    DELETE_DETAIL_FAIL,
    DELETE_DETAIL_START,
    DELETE_DETAIL_SUCCESS,
    GET_DETAIL_FAIL,
    GET_DETAIL_START,
    GET_DETAIL_SUCCESS,
    INIT_LIST,
    TRIGGER_DETAIL_REFRESH
} from "../actions/types";

const initialState = {
    refreshDetail: false,
    detailDeleted: false,
    fetching: false,
    data: {}
};

const detailReducer = function(state = initialState, action) {
    switch (action.type) {
        case GET_DETAIL_START: {
            return {
                ...state,
                fetching: true
            };
        }
        case GET_DETAIL_SUCCESS:
            return {
                ...state,
                data: action.payload || {},
                refreshDetail: false,
                fetching: false
            };
        case TRIGGER_DETAIL_REFRESH:
            return {
                ...state,
                refreshDetail: true,
                fetching: false
            };
        case DELETE_DETAIL_START:
            return {
                ...state,
                detailDeleted: false,
                fetching: true
            };
        case DELETE_DETAIL_SUCCESS:
            return {
                ...state,
                detailDeleted: true,
                fetching: false
            };
        case "@@router/LOCATION_CHANGE":
        case INIT_LIST:
            return {
                ...state,
                detailDeleted: false
            };
        case CHANGE_SERVICEGROUP_SUCCESS:
        case GET_DETAIL_FAIL:
        case DELETE_DETAIL_FAIL:
        case CLEAR_DETAIL_DATA:
            return {
                ...state,
                detailDeleted: false,
                refreshDetail: false,
                data: {}
            };
        default:
            return state;
    }
};

export default detailReducer;
