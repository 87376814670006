import React from "react";
import PropTypes from "prop-types";
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { makeStyles } from "@material-ui/core";
import DatefnsUtils from "@date-io/date-fns";

const getClasses = makeStyles({
    btn: {
        "& > button": {
            padding: 0
        }
    }
});

const DateTime = ({ name, label, value, helperText, error, onChange, ...allOtherProps }) => {
    const styles = getClasses();
    const {
        ampm = true,
        autoComplete,
        autoFocus,
        classes,
        className,
        color,
        defaultValue,
        disabled,
        FormHelperTextProps,
        fullWidth = true,
        InputLabelProps,
        InputProps,
        inputProps,
        inputRef,
        inputVariant = "outlined",
        margin,
        placeholder = "MM/DD/YYYY HH:mm",
        required,
        size,
        onBlur,
        variant
    } = allOtherProps;

    const eligibleProps = {
        ampm,
        autoComplete,
        autoFocus,
        classes,
        className,
        color,
        defaultValue,
        disabled,
        FormHelperTextProps,
        fullWidth,
        InputLabelProps,
        InputProps,
        inputProps,
        inputRef,
        inputVariant,
        margin,
        placeholder,
        required,
        size,
        onBlur,
        variant
    };

    return (
        <MuiPickersUtilsProvider utils={DatefnsUtils}>
            <KeyboardDateTimePicker
                {...eligibleProps}
                id={name}
                autoOk
                label={label}
                value={value ? value : null}
                format={`MM/dd/yyyy ${ampm ? "hh:mm a" : "HH:mm"}`}
                clearable
                helperText={`${error || ""}${error && helperText ? " - " : ""}${helperText || ""}`}
                onChange={onChange}
                error={Boolean(error)}
                InputAdornmentProps={{ classes: { root: styles.btn } }}
            />
        </MuiPickersUtilsProvider>
    );
};

DateTime.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.any
};

export default React.memo(DateTime);
