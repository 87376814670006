import store from "../store";
import { handleFieldChange } from "../actions/formActions";

/**
 * copyFieldDataToField
 * @param arrayOfFields array of objects
 */
const copyFieldDataToField = (arrayOfFields = []) => {
    const { dispatch, getState } = store;
    const { data } = getState().form;

    for (const copy of arrayOfFields){
        const [copyFrom, copyTo, defaultValue] = copy;
        dispatch(
            handleFieldChange({
                name: copyTo,
                value: data[copyFrom] || defaultValue || ""
            })
        );
    }

};

export default copyFieldDataToField;
