import React from "react";
import PropTypes from "prop-types";
import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

const Multiselect = ({ name, label, value, helperText, error, onChange, options, optName, optVal, ...allOtherProps }) => {
    const {
        autoComplete,
        autoHighlight,
        autoSelect,
        blurOnSelect,
        ChipProps,
        classes,
        className,
        clearOnEscape,
        clearText,
        closeIcon,
        closeText,
        debug,
        defaultValue,
        disableClearable,
        disableCloseOnSelect = true,
        disabled,
        disableListWrap = true,
        disablePortal,
        filterOptions,
        filterSelectedOptions,
        forcePopupIcon,
        freeSolo,
        fullWidth = true,
        getOptionDisabled,
        getOptionSelected,
        groupBy,
        includeInputInList,
        inputValue,
        ListboxComponent,
        ListboxProps,
        loading,
        loadingText,
        noOptionsText,
        onClose,
        onInputChange,
        onOpen,
        open,
        openText,
        PaperComponent,
        PopperComponent,
        popupIcon,
        renderGroup,
        renderInput,
        renderOption,
        renderTags,
        selectOnFocus,
        size,
        TextFieldProps,
        variant = "outlined"
    } = allOtherProps;

    const eligibleProps = {
        autoComplete,
        autoHighlight,
        autoSelect,
        blurOnSelect,
        ChipProps,
        classes,
        className,
        clearOnEscape,
        clearText,
        closeIcon,
        closeText,
        debug,
        defaultValue,
        disableClearable,
        disableCloseOnSelect,
        disabled,
        disableListWrap,
        disablePortal,
        filterOptions,
        filterSelectedOptions,
        forcePopupIcon,
        freeSolo,
        getOptionDisabled,
        getOptionSelected,
        groupBy,
        includeInputInList,
        inputValue,
        ListboxComponent,
        ListboxProps,
        loading,
        loadingText,
        noOptionsText,
        onClose,
        onInputChange,
        onOpen,
        open,
        openText,
        PaperComponent,
        PopperComponent,
        popupIcon,
        renderGroup,
        renderInput,
        renderOption,
        renderTags,
        selectOnFocus,
        size
    };

    options = Array.isArray(options) ? options : [];
    const selected = options.filter(f => value && value[f[optVal]]);

    const handleChange = (e, opt, ...rest) => {
        const value = opt && opt[optVal] ? opt[optVal] : opt;
        if (onChange && typeof onChange === "function") {
            onChange({ target: { value, name } }, opt, ...rest);
        }
    };

    return (
        <Autocomplete
            {...eligibleProps}
            multiple
            options={options}
            getOptionLabel={option => option[optName] || ""}
            value={selected}
            onChange={handleChange}
            disableCloseOnSelect={disableCloseOnSelect}
            renderInput={params => (
                <TextField
                    {...params}
                    {...TextFieldProps}
                    name={name}
                    variant={variant}
                    fullWidth={fullWidth}
                    label={label}
                    helperText={`${error || ""}${error && helperText ? " - " : ""}${helperText || ""}`}
                    error={Boolean(error)} />
            )}
        />
    );
};

Multiselect.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.any,
    options: PropTypes.array,
    optName: PropTypes.string.isRequired,
    optVal: PropTypes.string.isRequired
};

export default React.memo(Multiselect);
