import React from "react";
import PropTypes from "prop-types";
import emailMask from "text-mask-addons/dist/emailMask";
import TextField from "@material-ui/core/TextField";
import MaskedInput from "./MaskedInput";

const Email = ({ name, label, value, helperText, error, onChange, maxLength, ...allOtherProps }) => {
    const {
        autoComplete,
        autoFocus,
        classes,
        className,
        color,
        defaultValue,
        disabled,
        FormHelperTextProps,
        fullWidth = true,
        InputLabelProps,
        InputProps = {},
        inputProps = {},
        inputRef,
        margin,
        onFocus,
        onBlur,
        required,
        rows,
        rowsMax,
        size,
        variant = "outlined"
    } = allOtherProps;

    const eligibleProps = {
        autoComplete,
        autoFocus,
        classes,
        className,
        color,
        defaultValue,
        disabled,
        FormHelperTextProps,
        fullWidth,
        InputLabelProps,
        InputProps,
        inputProps,
        inputRef,
        margin,
        required,
        rows,
        rowsMax,
        size,
        onBlur,
        variant
    };

    return (
        <TextField
            {...eligibleProps}
            id={name}
            name={name}
            label={label}
            inputProps={{ ...inputProps, maxLength, mask: emailMask, disabled }}
            InputProps={{ ...InputProps, inputComponent: MaskedInput, onFocus }}
            value={value || ""}
            helperText={`${error || ""}${error && helperText ? " - " : ""}${helperText || ""}`}
            onChange={onChange}
            error={Boolean(error)}
        />
    );
};

Email.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.any
};

export default React.memo(Email);
