import React from "react";
import { Typography } from "@material-ui/core";
import { TextField } from "../components/fields";
import { addSnackbar, callEndpointV2, clearEndpoint, showDialog } from "../actions/layoutActions";
import validateForm, { validations } from "./validateForm";
import { clearData, handleFieldChange } from "../actions/formActions";
import store from "../store";

const emailValidation = validations.filter(f => f.fld === "email");

const showEmailDialog = ({ title = "Send Receipt", endpoint, filters = {}, email, showSnack = true, theme, onSend }) => {
    const { dispatch, getState } = store;

    if (email) {
        dispatch(handleFieldChange({ name: "email", value: email }));
    }

    dispatch(showDialog({
        title,
        maxWidth: "sm",
        content: (
            <div>
                <Typography variant="subtitle1" component="div" style={{ marginTop: -10, marginBottom: 10 }}>
                    Please verify the email address that you would like to send to.
                </Typography>
                <TextField name="email" label="Email Address" />
            </div>
        ),
        confirmLabel: "Send Email",
        confirmCondition: () => validateForm(["email"], emailValidation),
        confirmCallback: () => {
            const email = getState().form.data.email;
            const endpointKey = endpoint.replace(/\//g, "_");
            
            const callback = result => {
                if (result?.success && showSnack){
                    dispatch(addSnackbar(`${title} sent to ${email}`));
                    if (typeof onSend === "function"){
                        onSend(email);
                    }
                }
                dispatch(clearEndpoint(endpointKey));
            };
			
            dispatch(callEndpointV2({ endpoint, body: { filters: { ...filters, email } }, callback }));
            dispatch(clearData());
        },
        cancelCallback: () => dispatch(clearData()),
        theme
    }));
};

export default showEmailDialog;
