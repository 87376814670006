import React from "react";
import jwt from "jsonwebtoken";
import { connect } from "react-redux";
import { returnErrors } from "../actions/errorActions";
import { callEndpoint } from "../actions/layoutActions";
import Error from "./Error";
import PageNotFound from "./PageNotFound";

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentDidCatch(error, info) {
        const err = {
            location: window.location.toString(),
            type: error.name,
            status: 500,
            statusText: error.name,
            msg: error.message,
            stack: error.stack,
            misc: info
        };

        /* chunk err happens after a code push. Page tries to pull old files, needs reloaded, not much we can do so I don't want notified  */
        const chunkError = err.type === "ChunkLoadError" || /chunk/i.test(err.msg);

        // send error details to API
        if (!chunkError){
            if (this.props.isAuthenticated){
                this.props.callEndpoint("/api/general/error", { error: err });
            }
            else {
                const announcer = jwt.sign({ foo: "bar" }, "thisIsNottaKey", { expiresIn: "2m" });
                this.props.callEndpoint("/api/general/error2", { error: err, announcer });
            }
        }
        this.props.returnErrors(err, "UNKNOWN ERROR");
    }

    render() {
        if (this.state.hasError) {
            return (
                <>
                    <Error />
                    <PageNotFound />
                </>
            );
        }
        return this.props.children;
    }
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated
});

const mapDispatchToProps = { returnErrors, callEndpoint };

export default connect(mapStateToProps, mapDispatchToProps)(ErrorBoundary);
