import {
    SHOP_CALL_FAIL,
    SHOP_CALL_START,
    SHOP_CALL_SUCCESS,
    SHOP_CLEAR_DATA,
    SHOP_CLEAR_FILTERS,
    SHOP_HANDLE_FIELD_CHANGE,
    SHOP_HANDLE_FILTERS_CHANGE,
    SHOP_POS_COMPLETE_CHECKOUT,
    SHOP_SET_CART_DATA,
    SHOP_SET_CART_TOTALS,
    SHOP_SET_DATA,
    SHOP_TOGGLE_POS_CART
} from "../actions/types";

const initialState = {
    fetching: false,
    showCheckoutDialog: false,
    cart: {
        cartID: 0,
        items: []
    },
    activeProduct: {},
    selectedData: {},
    quantityData: {},
    customer: {},
    filters: {},
    cartOpen: true,
    cartDrawerWidth: 500,
    cashAmount: 0,
    tipAmount: 0,
    tipPercent: 0
};

const shopReducer = function(state = initialState, action) {
    switch (action.type) {
        case SHOP_CALL_START:
            return {
                ...state,
                fetching: true
            };

        case SHOP_CALL_SUCCESS:{
            if (action.stateKey){
                return {
                    ...state,
                    [action.stateKey]: action.payload,
                    fetching: false
                };
            }
            return {
                ...state,
                ...action.payload,
                fetching: false
            };
        }
        case SHOP_CALL_FAIL:
            return {
                ...state,
                fetching: false
            };

        case SHOP_SET_DATA: {
            const { name, value } = action.payload;
            return {
                ...state,
                [name]: value
            };
        }

        case SHOP_SET_CART_DATA: {
            const { name, value } = action.payload;
            return {
                ...state,
                cart: {
                    ...state.cart,
                    [name]: value
                }
            };
        }
       
        case SHOP_CLEAR_DATA:
            return {
                ...initialState
            };

        case SHOP_HANDLE_FIELD_CHANGE: {
            const { name: fieldName, value, remove } = action.payload;
            let selectedData = state.selectedData;
            let quantityData = state.quantityData;
            let cart = state.cart;
            let payType = state.payType;
            let orderType = state.orderType;
            let customer = state.customer;
            let cashAmount = state.cashAmount;
            let tipAmount = state.tipAmount;
            let tipPercent = state.tipPercent;
            let success = state.success;

            if (fieldName.slice(0, 8) === "quantity") {
                quantityData = { ...state.quantityData, [fieldName]: value };
            }
            else if (fieldName === "cart") {
                cart = value;
            }
            else if (fieldName === "payType") {
                payType = value;
            }
            else if (fieldName.slice(0, 8) === "customer") {
                customer = { ...state.customer, [fieldName]: value };
            }
            else if (fieldName === "cashAmount") {
                cashAmount = value;
            }
            else if (fieldName === "tipAmount") {
                tipAmount = value;
            }
            else if (fieldName === "tipPercent") {
                tipPercent = value;
            }
            else if (fieldName === "orderType") {
                orderType = value;
            }
            else if (fieldName === "success") {
                success = value;
            }
            else {
                selectedData = { ...state.selectedData, [fieldName]: value };
            }

            if (remove) delete selectedData[fieldName];
			
            return {
                ...state,
                cart,
                selectedData,
                quantityData,
                payType,
                orderType,
                customer,
                cashAmount,
                tipAmount,
                tipPercent,
                success
            };
        }

        case SHOP_TOGGLE_POS_CART: {
            return {
                ...state,
                cartOpen: !state.cartOpen
            };
        }

        case SHOP_POS_COMPLETE_CHECKOUT: {
            const { records, allSizes, allVariations, productVariationGroups, dropdowns } = state;
            return {
                ...initialState,
                records,
                allSizes,
                allVariations,
                productVariationGroups,
                dropdowns
            };
        }

        case SHOP_SET_CART_TOTALS: {
            return {
                ...state,
                cart: {
                    ...state.cart,
                    ...action.payload
                }
            };
        }
        
        case SHOP_HANDLE_FILTERS_CHANGE: {
            const { name, value } = action.payload;
            const filters = { ...state.filters, [name]: value };
            return {
                ...state,
                filters: {
                    ...filters
                }
            };
        }
		
        case SHOP_CLEAR_FILTERS: {
            return {
                ...state,
                filters: {}
            };
        }

        default:
            return state;
    }
};

// const parts = "cart.expiresIn.test".split(".");
// const newState = state;
// let ref;
// for (let i = 0; i < parts.length; i++){
// 	const part = parts[i];
//     if (newState[part]) ref = new
// }

export default shopReducer;
