/* eslint-disable max-len */
import React from "react";
import { Box, CircularProgress, FormControl, FormHelperText, IconButton, makeStyles } from "@material-ui/core";
import { Check, CheckBoxOutlineBlank } from "@material-ui/icons";
import { handleFieldChange } from "../../actions/formActions";
import { callEndpoint } from "../../actions/layoutActions";
import { useDispatch, useSelector } from "react-redux";
import { differenceInMilliseconds } from "date-fns";
import { green, red } from "@material-ui/core/colors";

const useStyles = makeStyles(theme => ({
    captcha: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        padding: theme.spacing(2, 2, 2, .5),
        width: 302,
        height: 74,
        background: theme.palette.grey[50],
        borderColor: props => props.error ? red[500] : theme.palette.grey[400],
        borderStyle: "solid",
        borderWidth: 1,
        cursor: "default",
        "& .progress": {
            margin: theme.spacing(.5, 1.5, 0)
        },
        "& .txt": {
            flex: 1
        },
        "& .check": {
            color: green[600],
            margin: theme.spacing(.5, 1, 0, 1.5)
        },
        "& .checkbox": {
            color: props => props.error ? red[500] : undefined
        }
    }
}));

const CaptchaIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" width="40" height="40">
        <path d="M64 31.955l-.033-1.37V4.687l-7.16 7.16C50.948 4.674 42.033.093 32.05.093c-10.4 0-19.622 4.96-25.458 12.64l11.736 11.86a15.55 15.55 0 0 1 4.754-5.334c2.05-1.6 4.952-2.906 8.968-2.906.485 0 .86.057 1.135.163 4.976.393 9.288 3.14 11.828 7.124l-8.307 8.307L64 31.953" fill="#1c3aa9" />
        <path d="M31.862.094l-1.37.033H4.594l7.16 7.16C4.58 13.147 0 22.06 0 32.046c0 10.4 4.96 19.622 12.64 25.458L24.5 45.768a15.55 15.55 0 0 1-5.334-4.754c-1.6-2.05-2.906-4.952-2.906-8.968 0-.485.057-.86.163-1.135.393-4.976 3.14-9.288 7.124-11.828l8.307 8.307L31.86.095" fill="#4285f4" />
        <path d="M.001 32.045l.033 1.37v25.898l7.16-7.16c5.86 7.173 14.774 11.754 24.76 11.754 10.4 0 19.622-4.96 25.458-12.64l-11.736-11.86a15.55 15.55 0 0 1-4.754 5.334c-2.05 1.6-4.952 2.906-8.968 2.906-.485 0-.86-.057-1.135-.163-4.976-.393-9.288-3.14-11.828-7.124l8.307-8.307c-10.522.04-22.4.066-27.295-.005" fill="#ababab" />
    </svg>
);

const RecaptchaV2 = () => {
    const dispatch = useDispatch();
    const data = useSelector(state => state.layout.endpoints._api_public_recaptcha_get || {});
    const fetching = useSelector(state => state.form.endpointFetching);
    const error = useSelector(state => state.form.errors.captcha);
    const classes = useStyles({ error });
    const timeoutRef = React.useRef();
    const [checked, setChecked] = React.useState(false);
    
    const handleClick = () => {
        if (!checked) dispatch(callEndpoint("/api/public/recaptcha/get", { filters: { ts: 1677613764798 } }));
    };

    React.useEffect(() => {
        if (!data.recaptchaToken && !data.expiresAt) return;
        const currentDateInEst = new Date(new Date().toLocaleString("en-US", { timeZone: "America/New_York" })); // force EST to match server
        const tokenExpiryDate = new Date(data.expiresAt.substring(0, data.expiresAt.length - 1)); // get rid of the Z otherwise it will be UTC
        const difference = differenceInMilliseconds(tokenExpiryDate, currentDateInEst);
        
        if (difference > 0) {
            timeoutRef.current = setTimeout(() => {
                setChecked(false);
                dispatch(handleFieldChange({ name: "captcha", remove: true }));
            }, difference);
            dispatch(handleFieldChange({ name: "captcha", value: data.recaptchaToken }));
            setChecked(true);
            return () => clearTimeout(timeoutRef.current);
        }
        else setChecked(false);
        
    }, [dispatch, data.expiresAt, data.recaptchaToken]);

    return (
        <FormControl error={Boolean(error)}>
            <div className={classes.captcha} onClick={handleClick}>
                <Box>
                    {fetching ?
                        <CircularProgress size={25} color="secondary" className="progress" /> :
                        checked ?
                            <Check className="check" fontSize="large" /> :
                            <IconButton>
                                <CheckBoxOutlineBlank className="checkbox" fontSize="large" />
                            </IconButton>
                    }
                </Box>
                <div className="txt">I&apos;m not a robot</div>
                <CaptchaIcon />
            </div>
            <FormHelperText>{error}</FormHelperText>
        </FormControl>
    );
};

export default React.memo(RecaptchaV2);
