import React from "react";
import { connect } from "react-redux";
import Number from "./base/Number";
import { handleFieldChange } from "../../actions/formActions";
import { isNumeric } from "../../helpers/utils";

const NumberField = ({ name, handleFieldChange, onChange, defValue, ...allOtherProps }) => {
    React.useEffect(() => {
        if (!allOtherProps.value) {
            if (isNumeric(defValue)) {
                handleFieldChange({ name, value: defValue });
            }
        }
    }, [name, handleFieldChange, defValue, allOtherProps.value]);
	
    const handleChange = e => {
        handleFieldChange({ name, value: e.target.value });
        if (typeof onChange === "function") onChange(e);
    };

    return <Number {...allOtherProps} name={name} onChange={handleChange} />;
};

const mapsStateToProps = (state, { name }) => {
    return {
        value: state.form.data[name],
        error: state.form.errors[name]
    };
};

const mapDispatchToProps = { handleFieldChange };

export default connect(mapsStateToProps, mapDispatchToProps)(NumberField);
