import React from "react";
import * as fieldComponents from "../components/fields";
import { Box, Link } from "@material-ui/core";
import format, { types } from "./format";

export const renderCustomFormFields = (fields = [], customProps) => {
    const rtn = [];
    for (const field of fields){
        const { typeID, component, label, fieldID, list, fullWidth = false, required, generatePDFTermsConditions } = field;
        let hint = field.hint || "";

        if (component && fieldComponents[component]){
            let CustomField = fieldComponents[component];
            let props = {};

            if (field.props){
                if (typeof field.props === "object") props = field.props;
                else if (typeof field.props === "string") props = JSON.parse(field.props);
            }

            if ([1, 2, 3, 4].includes(typeID)){
                props.maxLength = 999;
            }

            if (typeID === 15) {
                props = { ...props, fieldID, terms: field.terms, tblID: field.tblID, generatePDFTermsConditions };
                if (generatePDFTermsConditions) {
                    hint = `${hint} By checking this box you represent and warrant to us that you are at least 18 years of age (or older if you reside in a state where the majority age is older).
                    If you are not 18 years of age (or older if you reside in a state where the majority age is older), then by checking this box you confirm that you have obtained the consent
                    of your parent, legal guardian, or other authorized adult to agree to the Terms and Conditions on your behalf, (if applicable please enter their name in the "Signature Name" field below)`;
                }
            }

            if (customProps && typeof customProps === "object"){
                props = { ...props, ...customProps };
            }

            if (list){
                rtn.push(<CustomField label={label} name={`custom_field_${fieldID}`} key={`custom-field-${fieldID}`} helperText={hint}
                    optName="listValue" optVal="listID" full={fullWidth} TextFieldProps={{ required }} {...props} />);
            }
            else {
                rtn.push(<CustomField label={label} name={`custom_field_${fieldID}`} key={`custom-field-${fieldID}`} full={fullWidth} helperText={hint} required={required} {...props} />);
            }
            if (generatePDFTermsConditions && typeID === 15) {
                const TextField = fieldComponents.TextField;
                rtn.push(<TextField
                    label="Signature Name"
                    name={`custom_field_signatureName_${fieldID}`}
                    key={`custom-field-signatureName-${fieldID}`}
                    helperText="Please enter the name of the person who is agreeing to the Terms and Conditions. This name will be used on a signed PDF copy of the Terms and Conditions."
                    fullWidth
                    required={required}
                />);
            }
        }
    }
    return rtn;
};

export const formatCustomField = field => {
    const { typeID, scalarData, arrayData, decimals, fieldID, docInfo } = field;
    switch (typeID){
        case 2: {
            return <Link href={`mailto:${scalarData}`}>{scalarData || ""}</Link>;
        }
        case 3: {
            return format(types.PHONE, scalarData);
        }
        case 4: {
            return <Link href={scalarData}>{scalarData || ""}</Link>;
        }
        case 5: {
            return format(types.DECIMAL, scalarData, decimals);
        }
        case 6: {
            return format(types.DOLLAR, scalarData);
        }
        case 7: {
            return format(types.PERCENT, scalarData, decimals);
        }
        case 8: {
            return format(types.DATE, scalarData);
        }
        case 9: {
            return format(types.TIME, scalarData);
        }
        case 10: {
            return format(types.DATETIME, scalarData);
        }
        case 11: {
            return <Box whiteSpace="pre-wrap">{scalarData}</Box>;
        }
        case 12: {
            return format(types.BOOL, scalarData);
        }
        case 14: {
            return !arrayData ? null : arrayData.map((val, i) => <div key={`fld_${fieldID}_${i}`}>{val}</div>);
        }
        case 15: {
            if (docInfo && scalarData) {
                return (
                    <Box whiteSpace="pre-wrap">
                        Agree
                        <br />
                        <Link target="_blank" href={docInfo.path}>{docInfo?.name || "View"}</Link>
                    </Box>
                );
            }
            return scalarData ? "Agree" : "Not Agree";
        }
        case 16: {
            if (scalarData?.path) return <Link target="_blank" href={scalarData.path}>{scalarData?.name || "View"}</Link>;
            return scalarData?.name ? scalarData?.name : "";
        }
        default: return scalarData;
    }
};

export const renderCustomDetailFields = (fields = []) => {
    const rtn = [];
    for (const field of fields){
        rtn.push({ label: field.label, value: formatCustomField(field), showNul: true });
    }
    return rtn;
};

const helpers = {
    renderCustomFormFields,
    renderCustomDetailFields,
    formatCustomField
};

export default helpers;
