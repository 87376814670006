import React from "react";
import { connect } from "react-redux";
import ColorPicker from "./base/ColorPicker";
import { handleFieldChange } from "../../actions/formActions";

const ColorPickerField = ({ name, handleFieldChange, onChange, value, ...allOtherProps }) => {
    const handleChange = e => {
        handleFieldChange({ name, value: e.hex });
        if (typeof onChange === "function") onChange(e.hex);
    };

    return <ColorPicker {...allOtherProps} name={name} onChange={handleChange} value={value} />;
};

const mapsStateToProps = (state, { name }) => {
    return {
        value: state.form.data[name],
        error: state.form.errors[name]
    };
};

const mapDispatchToProps = { handleFieldChange };

export default connect(mapsStateToProps, mapDispatchToProps)(ColorPickerField);
