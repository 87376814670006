import React from "react";
import { FormControl, FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup } from "@material-ui/core";

const RadioFld = ({ name, label, value, helperText, error, onChange, row = true, shrink = false, options = [], ...allOtherProps }) => {
    const {
        classes,
        className
    } = allOtherProps;

    const eligibleProps = {
        classes,
        className
    };

    helperText = `${error || ""}${error && helperText ? " - " : ""}${helperText || ""}`.trim();
	
    return (
        <FormControl component="fieldset" error={Boolean(error)} {...eligibleProps}>
            <FormLabel component="legend" style={{ fontSize: shrink ? "0.8rem" : undefined }}>{label}</FormLabel>
            <RadioGroup row={row} name={name} value={value ? String(value) : ""} onChange={onChange}>
                {options.map((opt, i) => (
                    <FormControlLabel value={String(opt.value)} control={<Radio />} label={opt.label} disabled={opt.disabled} key={`option-${name}-${i}`} />
                ))}
            </RadioGroup>
            {helperText ? <FormHelperText>{helperText}</FormHelperText> : null}
        </FormControl>
    );
};

export default React.memo(RadioFld);
