import React from "react";
import PropTypes from "prop-types";
import { MenuItem, TextField } from "@material-ui/core";

const Menu = ({ name, label, value, helperText, error, onChange, options = [], optName = "label", optVal = "value", showDefault = true, defaultLabel = "Choose one...", ...allOtherProps }) => {
    const {
        autoComplete,
        autoFocus,
        classes,
        className,
        color,
        defaultValue,
        disabled,
        FormHelperTextProps,
        fullWidth = true,
        InputLabelProps,
        InputProps,
        inputProps,
        inputRef,
        margin,
        placeholder,
        required,
        SelectProps,
        size,
        variant = "outlined"
    } = allOtherProps;

    const eligibleProps = {
        autoComplete,
        autoFocus,
        classes,
        className,
        color,
        defaultValue,
        disabled,
        FormHelperTextProps,
        fullWidth,
        InputLabelProps,
        InputProps,
        inputProps,
        inputRef,
        margin,
        placeholder,
        required,
        SelectProps,
        size,
        variant
    };

    return (
        <TextField {...eligibleProps}
            id={name}
            label={label}
            value={value || ""}
            helperText={`${error || ""}${error && helperText ? " - " : ""}${helperText || ""}`}
            onChange={onChange}
            error={Boolean(error)}
            select>
            {showDefault && <MenuItem value="">{defaultLabel}</MenuItem>}
            {options.map(option => (
                <MenuItem key={`option-key-${option[optName]}`} value={option[optVal]}>
                    {option[optName]}
                </MenuItem>
            ))}
        </TextField>
    );
};

Menu.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.any,
    options: PropTypes.array,
    optName: PropTypes.string,
    optVal: PropTypes.string
};

export default React.memo(Menu);
