import React from "react";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";
import { InputAdornment, TextField } from "@material-ui/core";
import { isNumeric } from "../../../helpers/utils";

const Percent = ({ name, label, value, helperText, error, ...allOtherProps }) => {
    const {
        autoComplete,
        autoFocus,
        classes,
        className,
        color,
        defaultValue,
        disabled,
        FormHelperTextProps,
        fullWidth = true,
        InputLabelProps,
        InputProps = {},
        inputProps,
        inputRef,
        margin,
        onChange,
        onBlur,
        onFocus,
        placeholder,
        required,
        size,
        variant = "outlined",
        inputComponent = NumberFormatCustom
    } = allOtherProps;

    const eligibleProps = {
        autoComplete,
        autoFocus,
        classes,
        className,
        color,
        defaultValue,
        disabled,
        FormHelperTextProps,
        fullWidth,
        InputLabelProps,
        InputProps,
        inputProps,
        inputRef,
        margin,
        onChange,
        onBlur,
        placeholder,
        required,
        size,
        variant
    };

    return (
        <TextField
            {...eligibleProps}
            id={name}
            label={label}
            value={isNumeric(value) ? value : ""}
            type="text"
            helperText={`${error || ""}${error && helperText ? " - " : ""}${helperText || ""}`}
            error={Boolean(error)}
            InputProps={{
                endAdornment: <InputAdornment position="start">%</InputAdornment>,
                inputComponent: inputComponent,
                onFocus,
                ...InputProps
            }}
        />
    );
};

Percent.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;
    return (
        <NumberFormat {...other}
            getInputRef={inputRef}
            thousandSeparator
            isNumericString
            decimalScale={4}
            fixedDecimalScale
            onValueChange={values => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value
                    }
                });
            }}
        />
    );
}

export default React.memo(Percent);
