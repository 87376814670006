const { addDays, addWeeks, addMonths, addQuarters, addYears } = require("date-fns");

const getNextScheduledPaymentDate = (startDate, frequency) => {
    if (!startDate) return "";
    const start = new Date(startDate);
    switch (frequency) {
        case "Daily":
            return addDays(start, 1);
        case "Weekly":
            return addWeeks(start, 1);
        case "Biweekly":
            return addWeeks(start, 2);
        case "Monthly":
            return addMonths(start, 1);
        case "Bimonthly":
            return addMonths(start, 2);
        case "Quarterly":
            return addQuarters(start, 1);
        case "Semiannual":
            return addMonths(start, 6);
        case "Annual":
            return addYears(start, 1);
        default:
            return "";
    }
};

module.exports = getNextScheduledPaymentDate;
