import React from "react";
import { connect } from "react-redux";
import Date from "./base/Date";
import { isValid } from "date-fns";
import { handleFieldChange } from "../../actions/formActions";
import format, { types } from "../../helpers/format";

const DateField = ({ name, handleFieldChange, onChange, ...allOtherProps }) => {
    const handleChange = value => {
        if (isValid(value) || value === null) {
            value = value === null ? "" : format(types.DATE, value);
            handleFieldChange({ name, value });
            if (typeof onChange === "function") onChange(value);
        }
    };

    return <Date {...allOtherProps} onChange={handleChange} name={name} />;
};

const mapsStateToProps = (state, { name }) => {
    return {
        value: state.form.data[name],
        error: state.form.errors[name]
    };
};

const mapDispatchToProps = { handleFieldChange };

export default connect(mapsStateToProps, mapDispatchToProps)(DateField);
