import axios from "../common/axios";
import tokenConfig from "../helpers/tokenConfig";
import { returnErrors } from "./errorActions";
import { moduleRoutes } from "../common/modules";
import {
    ADD_DROPDOWN_OPTION,
    ADD_VALIDATION_ERROR,
    CALL_FORM_ENDPOINT_START,
    CALL_FORM_ENDPOINT_SUCCESS,
    CLEAR_DATA,
    GET_FORM_FAIL,
    GET_FORM_START,
    GET_FORM_SUCCESS,
    HANDLE_FIELD_CHANGE,
    HANDLE_FIELD_COPY,
    HANDLE_FIELD_PUSH,
    HANDLE_INDEXED_FIELD_CHANGE,
    REMOVE_VALIDATION_ERROR,
    SET_DROPDOWN,
    SET_STATE_BY_NAME,
    SET_STATE_NAME_BY_INDEX,
    SUBMIT_FORM_FAIL,
    SUBMIT_FORM_START,
    SUBMIT_FORM_SUCCESS,
    UPLOAD_ERROR,
    UPLOAD_FINISHED,
    UPLOAD_STARTED
} from "./types";

export const getFormByName = ({ name, filters = {}, action = "FORM", reset = true, showStatus = true, apiRoute, callback }) => (dispatch, getState) => {
    const body = { filters };
    if (!apiRoute) apiRoute = moduleRoutes[name][action];
    if (reset) dispatch({ type: CLEAR_DATA });
    if (showStatus) dispatch({ type: GET_FORM_START }); // shows loading indicator
    axios
        .post(apiRoute, body, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: GET_FORM_SUCCESS,
                payload: res.data
            });
            if (typeof callback === "function"){
                callback(res?.data);
            }
        })
        .catch(err => {
            dispatch(returnErrors(err, GET_FORM_FAIL));
            dispatch({
                type: GET_FORM_FAIL
            });
        });
};

export const callFormEndpoint = ({ name, data = {}, stateKey = "data", filters = {}, action = "FORM", reset = false, showStatus = false, apiRoute }) => (dispatch, getState) => {
    const form = getState().form.data;
    const body = { ...data, form, filters };
    if (!apiRoute) apiRoute = moduleRoutes[name][action];
    if (reset) dispatch({ type: CLEAR_DATA });
    if (showStatus) dispatch({ type: CALL_FORM_ENDPOINT_START }); // shows loading indicator
    axios
        .post(apiRoute, body, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: CALL_FORM_ENDPOINT_SUCCESS,
                payload: {
                    data: res.data,
                    stateKey
                }
            });
        })
        .catch(err => {
            dispatch(returnErrors(err, CALL_FORM_ENDPOINT_SUCCESS));
        });
};

export const submitFormByName = ({ name, filters, clone = false, action = "SAVE", callback, showStatus = true, apiRoute }) => (dispatch, getState) => {
    const form = getState().form.data;
    if (showStatus) dispatch({ type: SUBMIT_FORM_START });
    const body = { form: { ...form, clone }, filters };
    if (!apiRoute) apiRoute = moduleRoutes[name][action];
    axios
        .post(apiRoute, body, tokenConfig(getState))
        .then(res => {
            if (!res.data.status && res.data.msg === "The reCAPTCHA submitted was not valid") {
                dispatch({
                    type: ADD_VALIDATION_ERROR,
                    payload: {
                        name: "captcha",
                        error: res.data.msg
                    }
                });
                dispatch({
                    type: SUBMIT_FORM_FAIL
                });
            }
            else {
                dispatch({
                    type: SUBMIT_FORM_SUCCESS,
                    payload: res.data
                });
                if (typeof callback === "function"){
                    callback(res.data);
                }

            }
        })
        .catch(err => {
            dispatch(returnErrors(err, SUBMIT_FORM_FAIL));
            dispatch({
                type: SUBMIT_FORM_FAIL
            });
        });
};

export const uploadFileToServer = ({ fieldName, data, route, callback, filters = {}, v2 }) => (dispatch, getState) => {
    dispatch({ type: UPLOAD_STARTED });
    const body = new FormData();
    body.append(fieldName, data);
    body.append("filters", JSON.stringify(filters));
    axios
        .post(route, body, tokenConfig(getState))
        .then(res => {
            if (res.data[fieldName]){
                if (v2){
                    // I want to push uploads into a single upload folder, but don't want to refactor everything yet
                    dispatch(handleFieldPush("uploads", res.data[fieldName]));
                }
                else {
                    dispatch({
                        type: HANDLE_FIELD_CHANGE,
                        payload: {
                            name: fieldName,
                            value: res.data[fieldName]
                        }
                    });
                }
            }
            else {
                dispatch(setStateByName("uploadResponse", res.data));
            }
            if (typeof callback === "function") {
                dispatch(callback(fieldName, res.data[fieldName]));
            }
            dispatch({ type: UPLOAD_FINISHED });
        })
        .catch(err => {
            dispatch({ type: UPLOAD_FINISHED });
            dispatch(returnErrors(err, UPLOAD_ERROR));
            dispatch({
                type: UPLOAD_ERROR
            });
        });
};

export const handleFieldPush = (name, value) => {
    return {
        type: HANDLE_FIELD_PUSH,
        payload: {
            name,
            value
        }
    };
};

export const handleFieldChange = ({ name, value, remove }) => {
    return {
        type: HANDLE_FIELD_CHANGE,
        payload: {
            name,
            value,
            remove
        }
    };
};

export const handleIndexedFieldChange = (name, value, index, remove = false) => {
    return {
        type: HANDLE_INDEXED_FIELD_CHANGE,
        payload: {
            name,
            value,
            index,
            remove
        }
    };
};

export const handleFieldCopy = ({ fromFieldName, toFieldName }) => {
    return {
        type: HANDLE_FIELD_COPY,
        payload: { fromFieldName, toFieldName }
    };
};

export const addValidationError = (name, error) => {
    return {
        type: ADD_VALIDATION_ERROR,
        payload: {
            name,
            error
        }
    };
};

export const removeValidationError = name => {
    return {
        type: REMOVE_VALIDATION_ERROR,
        payload: {
            name
        }
    };
};

export const clearData = () => {
    return { type: CLEAR_DATA };
};

export const setStateByName = (name, value) => {
    return {
        type: SET_STATE_BY_NAME,
        payload: { name, value }
    };
};

export const setStateNameByIndex = (name, value, index) => {
    return {
        type: SET_STATE_NAME_BY_INDEX,
        payload: { name, value, index }
    };
};

export const setDropdown = ({ name, values }) => {
    return {
        type: SET_DROPDOWN,
        payload: {
            name,
            values
        }
    };
};

export const getDropdownOptions = ({ name, dropdownEndpoint, filters = {}, customEndpoint }) => (dispatch, getState) => {
    const body = { filters };
    dropdownEndpoint = dropdownEndpoint || name;
    const route = customEndpoint ? customEndpoint : `/api/general/dropdown/${(dropdownEndpoint).toLowerCase()}`;
    axios
        .post(route, body, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: SET_DROPDOWN,
                payload: {
                    name,
                    values: res.data
                }
            });
        });
};

export const addDropdownOption = ({ name, value, index }) => {
    return {
        type: ADD_DROPDOWN_OPTION,
        payload: {
            name,
            value,
            index
        }
    };
};
