import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import authReducer from "./authReducer";
import shopReducer from "./shopReducer";
import detailReducer from "./detailReducer";
import dragReducer from "./dragReducer";
import errorReducer from "./errorReducer";
import formReducer from "./formReducer";
import layoutReducer from "./layoutReducer";
import listReducer from "./listReducer";
import reportReducer from "./reportReducer";

const createRootReducer = history =>
    combineReducers({
        router: connectRouter(history),
        auth: authReducer,
        shop: shopReducer,
        detail: detailReducer,
        drag: dragReducer,
        error: errorReducer,
        form: formReducer,
        layout: layoutReducer,
        list: listReducer,
        report: reportReducer
    });

export default createRootReducer;
