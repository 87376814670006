import React from "react";
import { Box, Typography } from "@material-ui/core";
import logo from "../images/appLogo_345x91.png";

const PageNotFound = ({ time = 1000 }) => {
    const [show, setShow] = React.useState(false);
	
    React.useEffect(() => {
        const timeout = setTimeout(() => setShow(!show), time);
        return () => clearTimeout(timeout);
    }, []); // eslint-disable-line
	
    return (
        <React.Fragment>
            { show ?
                <Box display="flex" justifyContent="center" flexDirection="column" textAlign="center" height="100vh" paddingBottom={20}>
                    <Box textAlign="center">
                        <img src={logo} alt="logo" style={{ width: "100%", height: "auto", maxWidth: 345 }} />
                    </Box>
                    <Typography color="primary" style={{ fontSize: "calc(3em + 10vw)", lineHeight: "1em", fontWeight: "bold" }}>404</Typography>
                    <Typography variant="h1" gutterBottom style={{ textTransform: "uppercase", fontWeight: "bold" }}>
                        The page you&apos;re looking for<br />Cannot be found
                    </Typography>
                </Box> : null
            }
        </React.Fragment>
    );
};

export default React.memo(PageNotFound);
