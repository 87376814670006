import React, { Fragment } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import MaskedText from "./MaskedText";
import { useDispatch, useSelector } from "react-redux";
import { Alert } from "@material-ui/lab";
import { callEndpointV2 } from "../../actions/layoutActions";
import { AccountBalance } from "@material-ui/icons";
import SelectField from "./SelectField";

const getNumberMask = function(num) {
    return new Array(num).fill(0).map(() => /\d/);
};

const accountNumberMask = function(val) {
    const len = String(val).length;
    const min = Math.max(1, len);
    const max = Math.min(len, 17);
    if (len < min) return getNumberMask(min);
    else return getNumberMask(max);
};

const useStyles = makeStyles(theme => ({
    bank: {
        padding: theme.spacing(1, 0, 0, 0),
        marginBottom: theme.spacing(-1),
        display: "flex",
        "& .icon": {
            fontSize: 24,
            marginTop: theme.spacing(.325)
        },
        "& .info": {
            padding: theme.spacing(0, .825),
            fontSize: 14,
            "& .title": {
                fontWeight: 500
            }
        }
    }
}));

const RenderBankInfo = ({ lookup }) => {
    const classes = useStyles();
    if (lookup?.routing){
        const { name, addr, city, state, zip } = lookup;
        const cityState = [city, state, zip].filter(Boolean).join(" ");
        return (
            <div className={classes.bank}>
                <AccountBalance color="secondary" className="icon" />
                <div className="info">
                    <div className="title">Bank Info</div>
                    {name ? <div>{name}</div> : null}
                    {addr ? <div>{addr}</div> : null}
                    {cityState ? <div>{cityState}</div> : null}
                </div>
            </div>
        );
    }
    return null;
};

const ACHEntry = ({ getAccountStatus, variant = "outlined" }) => {
    const account = useSelector(state => state.form.data.account);
    const account2 = useSelector(state => state.form.data.account2);
    const routing = useSelector(state => state.form.data.routing?.trim());
    const dispatch = useDispatch();
	
    const [lookup, setLookup] = React.useState(null);
    const [accountStatus, setAccountStatus] = React.useState(null);

    if (typeof getAccountStatus === "function"){
        getAccountStatus(accountStatus);
    }

    React.useEffect(() => {
        if (account && account2 && String(account).length > 4){
            setAccountStatus(account === account2);
        }
        else {
            setAccountStatus(null);
        }
    }, [account, account2]);

    React.useEffect(() => {
        if (routing?.length === 9){
            const filters = { routing };
            dispatch(callEndpointV2({
                endpoint: "/api/processor/ach/lookup",
                body: { filters },
                callback: ach => setLookup(ach)
            }));
        }
        else {
            setLookup(null);
        }
    }, [routing]); // eslint-disable-line
    
    return (
        <Fragment>
            <Grid item xs={12}>
                <SelectField label="Account Type" name="transCode" optName="label" optVal="value" key="bill-form-transCode" variant={variant} fullWidth options={[
                    { label: "Checking Account", value: "27" },
                    { label: "Savings Account", value: "37" }
                ]} />
            </Grid>
            <Grid item xs={12}>
                <MaskedText label="Routing Number" mask={getNumberMask(9)} name="routing" variant={variant} />
                <RenderBankInfo lookup={lookup} />
            </Grid>
            <Grid item xs={12}>
                <MaskedText label="Account Number" mask={accountNumberMask} name="account" variant={variant} />
            </Grid>
            <Grid item xs={12}>
                <MaskedText label="Verify Account Number" mask={accountNumberMask} name="account2" variant={variant} />
                {accountStatus !== null ?
                    <Alert severity={accountStatus ? "success" : "error"}>
						Account numbers {accountStatus ? "" : "do not"} match
                    </Alert> : null
                }
            </Grid>
        </Fragment>
    );
};

export default React.memo(ACHEntry);
