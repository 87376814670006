import React from "react";
import Cropper from "react-easy-crop";
import { Button, TextField, Typography } from "@material-ui/core";
import { Image as ImageIcon } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import getCroppedImg from "../helpers/getCroppedImg";

const useStyles = makeStyles(theme => ({
    input: {
        display: "none"
    },
    imageIcon: {
        flex: 1,
        display: "flex"
    },
    fileName: {
        padding: theme.spacing(2),
        display: "inline-block",
        flex: 1
    }
}));

const ImageCropper = ({ name, onDone, onClose, requiredRatio }) => {
    const [crop, setCrop] = React.useState({ x: 0, y: 0 });
    const [zoom, setZoom] = React.useState(1);
    const [imageSrc, setImageSrc] = React.useState(null);
    const [croppedAreaPixels, setCroppedAreaPixels] = React.useState(null);
    const classes = useStyles();

    const [Numerator, Denominator] = String(requiredRatio).split(":");
    const ratio = Numerator && Denominator && !isNaN(Denominator) && Number(Denominator) !== 0 ? Number(Numerator) / Number(Denominator) : 1;

    const onCropComplete = React.useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }, []);

    const handleCrop = async e => {
        e.preventDefault();
        const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels);
        onDone(croppedImage);
        onClose();
    };

    const handleReset = () => {
        setCrop({ x: 0, y: 0 });
        setZoom(1);
        setImageSrc(null);
    };

    const onFileChange = async e => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            const imageUrl = URL.createObjectURL(file);
            setImageSrc(imageUrl);
        }
    };

    return (
        <>
            <div style={{ marginBottom: 20 }}>
                {imageSrc ?
                    <div style={{ position: "relative", minHeight: 500, marginBottom: 8 }}>
                        <Cropper
                            image={imageSrc}
                            crop={crop}
                            zoom={zoom}
                            aspect={ratio}
                            onCropChange={setCrop}
                            showGrid={false}
                            onCropComplete={onCropComplete}
                            onZoomChange={setZoom}
                        />
                    </div> :
                    <TextField
                        variant="outlined"
                        fullWidth
                        InputProps={{
                            inputComponent: () => <>
                                <input accept="image/*" className={classes.input} id={`image_cropper_${name}`} type="file" onChange={onFileChange} />
                                <label htmlFor={`image_cropper_${name}`} className={classes.imageIcon}>
                                    <Button title="Click to Upload Image" component="span" color="primary" size="large">
                                        <ImageIcon />
                                    </Button>
                                    <Typography variant="body1" className={classes.fileName}>Click to upload a file</Typography>
                                </label>
                            </>
                        }}
                    />
                }
            </div>
            {imageSrc ?
                <>
                    <Button onClick={handleCrop} variant="contained" color="primary" style={{ marginRight: 8 }}>Crop Image</Button>
                    <Button onClick={handleReset} variant="contained" style={{ marginRight: 8 }}>Reset</Button>
                </> : null
            }
            <Button onClick={onClose} variant="contained">Cancel</Button>
        </>
    );
};

export default React.memo(ImageCropper);
