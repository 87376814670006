import React from "react";
import { Box, Typography } from "@material-ui/core";

const ColorPreview = ({ color, width = 40, height = 40, showCode = true, border = "1px solid #888" }) => {
    if (!color) return null;
    return (
        <Box display="flex" style={{ cursor: "default" }}>
            <Box
                width={width}
                height={height}
                bgcolor={color}
                marginRight={1}
                border={border}
            />
            {showCode ? <Typography variant="subtitle2">{color}</Typography> : null}
        </Box>
    );
};

export default React.memo(ColorPreview);
