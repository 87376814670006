import { useSelector } from "react-redux";
import { createSelector } from "reselect";

const roleSelector = createSelector(
    state => state.auth.user?.userRoles,
    (state, props) => props.sysCode,
    (roles, sysCode) => {
        if (roles) {
            if (sysCode) {
                return roles.filter(f => f.sysCode === sysCode).reduce((summary, role) => {
                    summary[role.roleCode] = true;
                    return summary;
                }, {});
            }
            return roles.reduce((tree, { sysCode, roleCode }) => {
                if (!tree[sysCode]) tree[sysCode] = {};
                tree[sysCode][roleCode] = true;
                return tree;
            }, {});
        }
        return {};
    }
);

function useRoles(sysCode) {
    return useSelector(state => roleSelector(state, { sysCode })) || {};
}

export default useRoles;
