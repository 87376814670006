const shouldIntercept = error => {
    try {
        return error.response.status === 401;
    }
    catch (e) {
        return false;
    }
};

const setTokenData = (tokenData = {}) => { // eslint-disable-line no-unused-vars
    // do something with tokenData
};

const handleTokenRefresh = () => {
    // get new token data
};

const attachTokenToRequest = (request, token) => {
    request.headers["authorization"] = `Bearer ${ token}`;
};

const applyAppTokenRefreshInterceptor = (axiosClient, customOptions = {}) => {
    let isRefreshing = false;
    let failedQueue = [];

    const options = {
        attachTokenToRequest,
        handleTokenRefresh,
        setTokenData,
        shouldIntercept,
        ...customOptions
    };
    const processQueue = (error, token = null) => {
        failedQueue.forEach(prom => {
            if (error) {
                prom.reject(error);
            }
            else {
                prom.resolve(token);
            }
        });

        failedQueue = [];
    };

    const interceptor = error => {
        if (!options.shouldIntercept(error)) {
            return Promise.reject(error);
        }

        if (error.config._retry || error.config._queued) {
            return Promise.reject(error);
        }

        const originalRequest = error.config;
        if (isRefreshing) {
            return new Promise(function (resolve, reject) {
                failedQueue.push({ resolve, reject });
            }).then(token => {
                originalRequest._queued = true;
                options.attachTokenToRequest(originalRequest, token);
                return axiosClient.request(originalRequest);
            }).catch(() => {
                return Promise.reject(error); // Ignore refresh token request's "err" and return actual "error" for the original request
            });
        }

        originalRequest._retry = true;
        isRefreshing = true;
        return new Promise((resolve, reject) => {
            options.handleTokenRefresh.call(options.handleTokenRefresh)
                .then(tokenData => {
                    options.setTokenData(tokenData, axiosClient);
                    options.attachTokenToRequest(originalRequest, tokenData.accessToken);
                    processQueue(null, tokenData.accessToken);
                    resolve(axiosClient.request(originalRequest));
                })
                .catch(err => {
                    processQueue(err, null);
                    reject(err);
                })
                .finally(() => {
                    isRefreshing = false;
                });
        });
    };

    axiosClient.interceptors.response.use(undefined, interceptor);
};

export default applyAppTokenRefreshInterceptor;
