import React from "react";
import { useDispatch, useSelector } from "react-redux";
import isEqual from "react-fast-compare";
import { Box, Button, Card, CardHeader, Checkbox, Divider, ListItem, ListItemIcon, ListItemText, makeStyles } from "@material-ui/core";
import { red } from "@material-ui/core/colors";
import { Alert } from "@material-ui/lab";
import { FixedSizeList as List } from "react-window";
import { handleFieldChange } from "../../actions/formActions";
import { DoubleArrow } from "@material-ui/icons";
import { withStyles } from "@material-ui/styles";
import { useBreakpoint } from "../../helpers";
import SearchBox from "../SearchBox";

const useStyles = makeStyles(theme => ({
    cardHeader: {
        padding: theme.spacing(1, 2)
    },
    headerTitle: {
        fontWeight: theme.typography.fontWeightMedium,
        fontSize: theme.typography.fontSize + 2,
        color: custom => custom.error ? red["500"] : theme.palette.primary.main
    },
    button: {
        margin: theme.spacing(0.5, 0),
        width: 90
    },
    listBox: {
        [theme.breakpoints.only("xs")]: {
            flexDirection: "column"
        }
    }
}));

const DoubleArrowLeft = withStyles({
    root: {
        transform: "rotate(180deg)"
    }
})(DoubleArrow);

function not(arrayA, arrayB, key) {
    return arrayA.filter(a => arrayB.every(b => String(b[key]) !== String(a[key])));
}

function intersection(arrayA, arrayB, key) {
    return arrayA.filter(a => arrayB.some(b => String(b[key]) === String(a[key])));
}

function union(arrayA, arrayB, key){
    return arrayB.concat(arrayA).filter(function(f) {
        return this.has(f[key]) ? false : this.add(f[key]);
    }, new Set());
}

const filterItems = (items, query, optName, optName2, customListItem) => {
    if (!query) return items;
    
    const lcQuery = query.toLowerCase();

    if (customListItem) {
        return items.filter(item => JSON.stringify(item).toLowerCase().includes(lcQuery));
    }

    return items.filter(item =>
        (item[optName] && item[optName].toLowerCase().includes(lcQuery)) ||
        (optName2 && item[optName2] && item[optName2].toLowerCase().includes(lcQuery))
    );
};

function useTransferList(name, optVal, optName) {
    const data = useSelector(state => state.form.data[name] || {});
    const options = useSelector(state => state.form.dropdowns[name] || []);
    const dispatch = useDispatch();
    
    const chosen = React.useMemo(() => options.filter(f => data[f[optVal]]), [options, data, optVal]);
    const sel = React.useMemo(() => intersection(chosen, options, optVal), [options, chosen, optVal]);
    const avail = React.useMemo(() => not(options, chosen, optVal), [options, chosen, optVal]);
    
    const [checked, setChecked] = React.useState([]);
    const [available, setAvailable] = React.useState(avail);
    const [selected, setSelected] = React.useState(sel);
    
    React.useEffect(() => {
        if (!isEqual(avail, available)) setAvailable(avail);
    }, [avail, available]);

    React.useEffect(() => {
        if (!isEqual(sel, selected)) setSelected(sel);
    }, [sel, selected]);
	
    const sort = (a, b) => {
        if (a[optName] < b[optName]) return -1;
        if (a[optName] > b[optName]) return 1;
        return 0;
    };
	
    const availableChecked = intersection(checked, available, optVal);
    const selectedChecked = intersection(checked, selected, optVal);
	
    const handleToggle = value => {
        const currentIndex = checked.findIndex(f => String(f[optVal]) === String(value[optVal]));
        const newChecked = [...checked];
		
        if (currentIndex === -1) {
            newChecked.push(value);
        }
        else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const numberOfChecked = items => intersection(checked, items, optVal).length;

    const handleToggleAll = items => () => {
        if (numberOfChecked(items) === items.length) {
            setChecked(not(checked, items, optVal));
        }
        else {
            setChecked(union(checked, items, optVal));
        }
    };

    const handleCheckedSelected = () => {
        const newSelected = selected.concat(availableChecked).sort(sort);
		
        const value = newSelected.reduce((object, item) => {
            object[item[optVal]] = true;
            return object;
        }, {});
		
        dispatch(handleFieldChange({ name, value }));
        setSelected(newSelected);
        setAvailable(not(available, availableChecked, optVal).sort(sort));
        setChecked(not(checked, availableChecked, optVal));
    };

    const handleCheckedAvailable = () => {
        const newSelected = not(selected, selectedChecked, optVal).sort(sort);
		
        const value = newSelected.reduce((object, item) => {
            object[item[optVal]] = true;
            return object;
        }, {});
		
        dispatch(handleFieldChange({ name, value }));
        setAvailable(available.concat(selectedChecked).sort(sort));
        setSelected(newSelected);
        setChecked(not(checked, selectedChecked, optVal));
    };

    return {
        available,
        selected,
        checked,
        handleToggle,
        handleToggleAll,
        handleCheckedSelected,
        handleCheckedAvailable,
        numberOfChecked,
        availableChecked,
        selectedChecked
    };
}

const RenderListItem = React.memo(({ index, style, data, customListItem, optVal, optName, optName2, checked, handleToggle } ) => {
    const isChecked = React.useCallback(item => {
        return checked.some(f => String(f[optVal]) === String(item[optVal]));
    }, [checked, optVal]);

    const item = data[index];
    return (
        <ListItem style={style} key={item[optVal]} role="listitem" button onClick={() => handleToggle(item)}>
            <ListItemIcon>
                <Checkbox checked={isChecked(item)} tabIndex={-1} disableRipple color="primary" />
            </ListItemIcon>
            {customListItem && typeof customListItem === "function" ?
                <ListItemText disableTypography>{customListItem({ item })}</ListItemText> :
                <ListItemText primary={item[optName]} secondary={optName2 && item[optName2] ? item[optName2] : null} />
            }
        </ListItem>
    );
});

const TransferButtons = React.memo(({ classes, handleCheckedSelected, handleCheckedAvailable, availableChecked, selectedChecked }) => {
    return (
        <Box>
            <Box display="flex" flexDirection="column" alignItems="center" padding={2}>
                <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    className={classes.button}
                    onClick={handleCheckedSelected}
                    disabled={availableChecked.length === 0}
                >
                    <DoubleArrow />
                </Button>
                <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    className={classes.button}
                    onClick={handleCheckedAvailable}
                    disabled={selectedChecked.length === 0}
                >
                    <DoubleArrowLeft />
                </Button>
            </Box>
        </Box>
    );
});

const RenderList = React.memo(({ items, title, name, optVal, optName, optName2, customListItem, checked, handleToggle, handleToggleAll, numberOfChecked }) => {
    const error = useSelector(state => state.form.errors[name]);
    const classes = useStyles({ error });
    const checkedItemCount = numberOfChecked(items);
    const breakpoint = useBreakpoint();

    const rowHeight = React.useMemo(() => {
        return { xs: 70, sm: 180, md: 100, lg: 80, xl: 70 }[breakpoint] || 60;
    }, [breakpoint]);

    return (
        <Card>
            <CardHeader
                className={classes.cardHeader}
                classes={{ title: classes.headerTitle }}
                avatar={
                    <Checkbox
                        color="primary"
                        onClick={handleToggleAll(items)}
                        checked={checkedItemCount === items.length && items.length !== 0}
                        indeterminate={checkedItemCount !== items.length && checkedItemCount !== 0}
                        disabled={items.length === 0}
                    />
                }
                title={title}
                subheader={`${checkedItemCount}/${items.length} selected`}
            />
            <Divider />
            <List className={classes.list} itemData={items} height={300} itemCount={items.length} itemSize={rowHeight} dense component="div" role="list">
                {({ style, index, data }) =>
                    <RenderListItem
                        style={style}
                        index={index}
                        data={data}
                        customListItem={customListItem}
                        optVal={optVal}
                        optName={optName}
                        optName2={optName2}
                        checked={checked}
                        handleToggle={handleToggle}
                    />
                }
            </List>
        </Card>
    );
});

const TransferList = ({ optVal, optName, optName2, label, name, selLabel = "assigned", availLabel = "unassigned", msg, customListItem, useSearch = false }) => {
    const {
        available,
        selected,
        checked,
        handleToggle,
        handleToggleAll,
        handleCheckedSelected,
        handleCheckedAvailable,
        numberOfChecked,
        availableChecked,
        selectedChecked
    } = useTransferList(name, optVal, optName);

    const error = useSelector(state => state.form.errors[name]);
    const classes = useStyles({ error });

    const [searchTerm, setSearchTerm] = React.useState("");
    const filteredAvailable = filterItems(available, searchTerm, optName, optName2, customListItem);

    const baseRenderListProps = {
        name,
        optVal,
        optName,
        optName2,
        customListItem,
        checked,
        handleToggle,
        handleToggleAll,
        numberOfChecked
    };

    return (
        <React.Fragment>
            {error && <Box marginBottom={1}><Alert severity="error">{error}</Alert></Box>}
            {msg && <Box paddingBottom={1} paddingX={.5}>{msg}</Box>}
            {useSearch &&
				<Box padding={1}>
				    <SearchBox value={searchTerm} placeholder="Filter Available..." onChange={e => setSearchTerm(e.target.value)} onClear={() => setSearchTerm("")} />
				</Box>
            }
            <Box className={classes.listBox} display="flex">
                <Box flex="1" className="Mui-error">
                    <RenderList items={filteredAvailable} title={`${label} (${availLabel})`} {...baseRenderListProps} />
                </Box>
                <TransferButtons
                    classes={classes}
                    handleCheckedSelected={handleCheckedSelected}
                    handleCheckedAvailable={handleCheckedAvailable}
                    availableChecked={availableChecked}
                    selectedChecked={selectedChecked}
                />
                <Box flex="1">
                    <RenderList items={selected} title={`${label} (${selLabel})`} {...baseRenderListProps} />
                </Box>
            </Box>
        </React.Fragment>
    );
};

export default React.memo(TransferList);

