import React from "react";
import { CircularProgress, Typography } from "@material-ui/core";

const styles = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100%"
};

const Loader = ({ size = 75, color = "primary", style = {}, helperText }) => {
    return (
        <div style={{ ...styles, ...style }}>
            <CircularProgress size={size} color={color} disableShrink />
            {helperText ? <Typography variant="h5" style={{ margin: 10 }} color={color}>{helperText}</Typography> : null}
        </div>
    );
};

export default React.memo(Loader);
