import { CLEAR_ERRORS, GET_ERRORS } from "../actions/types";

const initialState = {
    type: null,
    status: null,
    statusText: null,
    msg: null,
    misc: null
};

const errorReducer = function(state = initialState, action) {
    switch (action.type) {
        case GET_ERRORS:
            return {
                ...state,
                ...action.payload
            };
        case CLEAR_ERRORS:
            return {
                ...state,
                ...initialState
            };
        default:
            return state;
    }
};

export default errorReducer;
