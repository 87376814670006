/**
 * getParentURL removes the sub-most child from url
 * example: /grandparent/parent/child => /grandparent/parent
 * @param {string} url
 * @param {number} depth number of children that will be removed from url
 */
const getParentURL = (url = "", depth = 1) => {
    return url
        .split("/")
        .reverse()
        .slice(depth)
        .reverse()
        .join("/");
};

export default getParentURL;
