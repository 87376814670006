import React from "react";
import { connect } from "react-redux";
import { Avatar, IconButton, Slide, Snackbar } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import { makeStyles } from "@material-ui/core/styles";
import { Close } from "@material-ui/icons";
import { processSnack } from "../actions/layoutActions";

const useStyles = makeStyles(theme => ({
    close: {
        padding: theme.spacing(0.5)
    },
    content: {
        display: "flex",
        alignItems: "center"
    },
    icon: {
        backgroundColor: theme.palette.warning.main
    },
    msg: {
        marginLeft: theme.spacing(1.2)
    }
}));

function SlideTransition(props) {
    return <Slide {...props} direction="up" />;
}

const GlobalSnackbar = ({ snacks, processSnack }) => {
    const classes = useStyles();
    const [snack] = snacks || [];

    const handleClose = (e, reason) => {
        if (reason !== "clickaway"){
            processSnack();
        }
    };

    if (!snack?.key) return null;

    return (
        <Snackbar
            key={snack.key}
            TransitionComponent={SlideTransition}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            ContentProps={{ classes: { message: classes.content } }}
            open
            autoHideDuration={4000}
            onClose={handleClose}
            message={(
                <React.Fragment>
                    <Avatar className={classes.icon}>
                        <InfoIcon />
                    </Avatar>
                    <span className={classes.msg}>{snack.message}</span>
                </React.Fragment>
            )}
            action={
                <>
                    {snack.action ? snack.action : null}
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        className={classes.close}
                        onClick={handleClose}
                    >
                        <Close />
                    </IconButton>
                </>
            }
        />
    );
};

const mapStateToProps = state => ({
    snacks: state.layout.snacks
});

const mapDispatchToProps = { processSnack };

export default connect(mapStateToProps, mapDispatchToProps)(GlobalSnackbar);
