import React from "react";
import { connect } from "react-redux";
import { Grid } from "@material-ui/core";
import { MaskedText } from "./base";
import { handleFieldChange } from "../../actions/formActions";
import CreditCardPreview from "../CreditCardPreview";

/* This component should be wrapped with a <Grid container>, e.g. BillingPayment */
const CreditCardEntry = ({ handleFieldChange, variant = "outlined", onBlur, exp, cvv, name, cardNumber, cardType, errorCard, errorExp, errorCvv, children }) => {
    const [focused, setFocused] = React.useState(null);
	
    const handleFocus = e => {
        const alias = { cardNumber: "number", cvv: "cvc", exp: "expiry" };
        const { name } = e.target;
        setFocused(alias[name] || name );
    };
	
    const handleBlur = e => {
        if (typeof onBlur === "function") onBlur(e);
        handleFocus({ target: { name: "cardNumber" } });
    };

    return (
        <React.Fragment>
            <Grid item xs={12} sm={6}>
                <MaskedText
                    label="Credit/Debit Card"
                    mask={[/\d/, /\d/, /\d/, /\d/, " ", /\d/, /\d/, /\d/, /\d/, " ", /\d/, /\d/, /\d/, /\d/, " ", /\d/, /\d/, /\d/, /\d/]}
                    variant={variant}
                    value={cardNumber || ""}
                    name="cardNumber"
                    focusName="number"
                    onChange={e => handleFieldChange({ name: "cardNumber", value: e.target.value.replace(/\s/g, "") })}
                    error={errorCard}
                    onFocus={handleFocus}
                    autoComplete="cc-number"
                    required
                    inputProps={{ inputMode: "numeric" }}
                />
            </Grid>
            <Grid item xs={6} sm={3}>
                <MaskedText
                    label="Expiration"
                    mask={[/\d/, /\d/, "/", /\d/, /\d/]}
                    variant={variant}
                    value={exp || ""}
                    name="exp"
                    onChange={e => handleFieldChange({ name: "exp", value: e.target.value })}
                    error={errorExp}
                    onFocus={handleFocus}
                    autoComplete="cc-exp"
                    required
                    inputProps={{ inputMode: "numeric" }}
                />
            </Grid>
            <Grid item xs={6} sm={3}>
                <MaskedText
                    label="CVC"
                    mask={[/\d/, /\d/, /\d/, /\d/]}
                    variant={variant}
                    value={cvv || ""}
                    name="cvv"
                    onChange={e => handleFieldChange({ name: "cvv", value: e.target.value.trim() })}
                    error={errorCvv}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    required
                    autoComplete="cc-csc"
                    inputProps={{ inputMode: "numeric" }}
                />
            </Grid>
            { children } {/* added for pos zip code field */}
            <Grid item xs={12}>
                <div style={{ width: 290, maxWidth: "100%" }}>
                    <CreditCardPreview
                        cvc={cvv || ""}
                        expiry={exp || ""}
                        name={name || ""}
                        number={cardNumber || ""}
                        acceptedCards={["visa", "discover", "mastercard", "amex"]}
                        focused={focused}
                        callback={({ issuer }) => cardType !== issuer && issuer !== "unknown" && handleFieldChange({ name: "cardType", value: issuer })}
                    />
                </div>
            </Grid>
        </React.Fragment>
    );
};

const mapsStateToProps = state => ({
    exp: state.form.data.exp,
    cvv: state.form.data.cvv,
    name: state.form.data.name,
    cardNumber: state.form.data.cardNumber,
    cardType: state.form.data.cardType,
    errorCvv: state.form.errors.cvv,
    errorCard: state.form.errors.cardNumber,
    errorExp: state.form.errors.exp
});

const mapDispatchToProps = { handleFieldChange };

export default connect(mapsStateToProps, mapDispatchToProps)(CreditCardEntry);
