import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { LinearProgress } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    root: {
        width: "100%",
        position: "absolute",
        top: 0,
        "& > * + *": {
		  marginTop: theme.spacing(2)
        }
	  }
}));

const LoadingIndicatorBar = () => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <LinearProgress color="secondary" />
        </div>
    );
};

export default React.memo(LoadingIndicatorBar);
