import React from "react";
import { connect } from "react-redux";
import Time from "./base/Time";
import { isValid } from "date-fns";
import { handleFieldChange } from "../../actions/formActions";
import { padNumber } from "../../helpers/format";

const TimeField = ({ name, handleFieldChange, onChange, ...allOtherProps }) => {
    const handleChange = value => {
        if (isValid(value) || value === null) {
            if (value === null) value = "";
            if (value instanceof Date) {
                const [time, aa] = value.toLocaleTimeString().split(/\s/);
                let [hh, mm] = time.split(":");
                hh = padNumber(hh);
                if (aa === "PM" && hh !== "12") hh = padNumber(Number(hh) + 12);
                if (aa === "AM" && hh === "12") hh = "00";
                value = `1900-01-01T${hh}:${mm}:00`;
            }
            handleFieldChange({ name, value });
            if (typeof onChange === "function") onChange(value);
        }
    };

    return <Time {...allOtherProps} onChange={handleChange} name={name} />;
};

const mapsStateToProps = (state, { name }) => {
    return {
        value: state.form.data[name],
        error: state.form.errors[name]
    };
};

const mapDispatchToProps = { handleFieldChange };

export default connect(mapsStateToProps, mapDispatchToProps)(TimeField);
