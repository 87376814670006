import React from "react";
import { connect } from "react-redux";
import { handleFieldChange } from "../../actions/formActions";
import { FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, makeStyles, Radio, RadioGroup } from "@material-ui/core";
import { Menu } from "../fields/base";
import format, { types } from "../../helpers/format";

const useStyles = makeStyles(theme => ({
    container: {
        padding: theme.spacing(2)
    },
    radioGroup: {
        marginLeft: theme.spacing(1)
    }
}));

const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
const weekdays = new Array(7).fill(1).map((n, i) => ({
    label: daysOfWeek[i],
    value: i + 1
}));

const days = new Array(31).fill(1).map((n, i) => ({
    value: i + 1,
    label: `On the ${format(types.ORDINAL, i + 1)}`
}));

const days2 = new Array(15).fill(1).map((n, i) => ({
    value: i + 1,
    label: `${format(types.ORDINAL, i + 1)} and ${i === 14 ? "EOM" : format(types.ORDINAL, i + 16)}`
}));

const settings = {
    W: { options: weekdays, label: "Choose Weekday" },
    W2: { options: weekdays, label: "Choose Weekday" },
    "2M": { options: days2, label: "Choose Days" },
    M: { options: days, label: "Choose Day" }
};

const ScheduleField = ({ name, handleFieldChange, onChange, data, errors }) => {
    const classes = useStyles();
    const value = data[name] || "";
    const error = errors[name] || false;
    const { options = [{ value: 0, label: "Please select frequency" }], label = "" } = settings[value] || {};
	
    const handleChange = e => {
        handleFieldChange({ name: `${name}_day`, value: "" });
        handleFieldChange({ name, value: e.target.value });
        if (typeof onChange === "function") onChange(e);
    };
	
    const handleDayChange = e => {
        handleFieldChange({ name: `${name}_day`, value: e.target.value });
    };

    return (
        <Grid container spacing={0} className={classes.container}>
            <Grid item xs={12} sm={6}>
                <FormControl component="fieldset" error={Boolean(error)}>
                    <FormLabel component="legend">Select payment frequency</FormLabel>
                    <RadioGroup name={name} value={value} onChange={handleChange} className={classes.radioGroup}>
                        <FormControlLabel value="W" control={<Radio color="primary" />} label="Weekly" />
                        <FormControlLabel value="W2" control={<Radio color="primary" />} label="Every Other Week" />
                        <FormControlLabel value="2M" control={<Radio color="primary" />} label="Twice a Month" />
                        <FormControlLabel value="M" control={<Radio color="primary" />} label="Once a Month" />
                    </RadioGroup>
                    {error ? <FormHelperText>{error}</FormHelperText> : null}
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Menu
                    label={label || ""}
                    options={options}
                    optName="label"
                    optVal="value"
                    value={data[`${name}_day`] || ""}
                    error={errors[`${name}_day`]}
                    onChange={handleDayChange}
                    showDefault={false} />
            </Grid>
        </Grid>
    );
};

const mapsStateToProps = state => {
    return {
        data: state.form.data,
        errors: state.form.errors
    };
};

const mapDispatchToProps = { handleFieldChange };

export default connect(mapsStateToProps, mapDispatchToProps)(ScheduleField);
