import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, InputAdornment, TextField } from "@material-ui/core";
import { AttachFile, Delete } from "@material-ui/icons";
import { handleFieldChange, uploadFileToServer } from "../../actions/formActions";
import { format } from "../../helpers";
import { types } from "../../helpers/format";
import Loader from "../Loader";

const FileField = ({ name, label, helperText, variant = "outlined" }) => {
    const file = useSelector(state => state.form.data[name]);
    const error = useSelector(state => state.form.errors[name]);
    const uploading = useSelector(state => state.form.uploading);
    const dispatch = useDispatch();
    const [clicked, setClicked] = React.useState(false);

    React.useEffect(() => {
        if (file?.name) setClicked(false);
    }, [file?.name]);

    const handleUpload = e => {
        if (e.target.files && e.target.files.length){
            setClicked(true);
            const key = "eServices_123";
            const token = btoa(`${btoa(key)}.${key}`);
            dispatch(uploadFileToServer({ route: "/api/document/upload_fld", fieldName: name, data: e.target.files[0], filters: { token } }));
        }
    };

    const handleRemove = () => {
        dispatch(handleFieldChange({ name, value: "" }));
    };

    if (clicked && uploading) return <Loader size={35} />;

    if (file?.name){
        return (
            <TextField
                name={name}
                id={name}
                variant={variant}
                label={label}
                value={`${file.name} ${file.size ? format(types.FILESIZE, file.size) : ""}`}
                fullWidth
                InputLabelProps={{ shrink: true }}
                error={Boolean(error)}
                helperText={error || helperText}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <Button color="primary" onClick={handleRemove} variant="contained" disableElevation >
                                <Delete fontSize="small" /> Delete
                            </Button>
                        </InputAdornment>
                    )
                }}
            />
        );
    }
	
    return (
        <TextField
            name={name}
            id={name}
            variant={variant}
            label={label}
            value=""
            fullWidth
            InputLabelProps={{ shrink: true }}
            error={Boolean(error)}
            helperText={error || helperText}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <Button color="primary" component="label" variant="contained" disableElevation htmlFor={`image_upload_${name}`}>
                            <AttachFile fontSize="small" /> Attach
                            <input id={`image_upload_${name}`} multiple type="file" onChange={handleUpload} hidden />
                        </Button>
                    </InputAdornment>
                )
            }}
        />
    );
};

export default React.memo(FileField);
