import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
const defaults = createMuiTheme();

const overrides = {
    MuiInputBase: {
        inputMultiline: {
            resize: "both"
        }
    },
    MuiIconButton: {
        root: {
            "&.medium": {
                padding: defaults.spacing(1)
            }
        }
    },
    MuiAccordionSummary: {
        root: {
            marginBottom: defaults.spacing(2),
            "&$expanded": {
                minHeight: "unset"
            }
        }
    },
    MuiTable: {
        root: {
            "@media print": {
                pageBreakInside: "auto"
            }
        }
    },
    MuiTableRow: {
        root: {
            verticalAlign: "top",
            "@media print": {
                pageBreakInside: "avoid",
                pageBreakAfter: "auto"
            }
        }
    },
    MuiTableCell: {
        root: {
            padding: "10px 6px",
            "&:first-child": {
                paddingLeft: 16
            },
            "&:last-child": {
                paddingRight: 16
            },
            "&.nowrap": {
                whiteSpace: "nowrap",
                flexWrap: "nowrap"
            },
            "@media print": {
                padding: "6px 3px !important",
                overflowWrap: "break-word"
            }
        },
        sizeSmall: {
            padding: 6,
            "&:first-child": {
                paddingLeft: 16
            },
            "&:last-child": {
                paddingRight: 16
            }
        },
        head: {
            verticalAlign: "bottom"
        },
        footer: {
            "@media print": {
                color: "#000",
                fontWeight: 500
            }
        }
    }
};

export default overrides;
