const palette = {
    primary: {
        main: "#007698"
    },
    secondary: {
        main: "#848e99"
    }
};

export default palette;
