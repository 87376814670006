import store from "../store";
import { handleFieldChange } from "../actions/formActions";
import { clearEndpoint } from "../actions/layoutActions";
import format, { types } from "./format";
import { addMonths } from "date-fns";

export const getUpdatedMembershipDates = ({ beginDate, endDate, periodMonths, extraPeriods }) => {
    const { getState } = store;
    const data = getState().form.data;
    const endpoint = getState().layout.endpoints?._api_member_membership_dates;

    if (!beginDate && data.beginDate) beginDate = data.beginDate;
    if (typeof endpoint !== "undefined"){
        beginDate = endpoint.beginDate;
        endDate = endpoint.endDate;
    }
	
    beginDate = format(types.DATEISO, beginDate);
    extraPeriods = extraPeriods || data.extraPeriods;
    periodMonths = periodMonths || 0;

    if (!endDate){
        let [year, month, day = 0] = beginDate.split("-");
        day = day > 0 ? day - 1 : day;
        endDate = new Date(year, Number(month) + Number(periodMonths) - 1, day);
        if (endDate && endDate.toJSON && endDate.toJSON()) {
            const [yy, mm, dd] = endDate.toJSON().split(/[T-]/);
            endDate = `${yy}-${mm}-${dd}`;
        }
    }

    if (endDate && extraPeriods > 0) {
        endDate = addMonths(new Date(format(types.DATEISO, endDate)), extraPeriods * periodMonths);
        if (endDate && endDate.toJSON && endDate.toJSON()) {
            const [yy, mm, dd] = endDate.toJSON().split(/[T-]/);
            endDate = `${yy}-${mm}-${dd}`;
        }
    }

    return { beginDate, endDate };

};

export const updateMembershipFormDates = ({ beginDate, endDate, periodMonths, extraPeriods }) => {
    const { dispatch } = store;
    ({ beginDate, endDate } = getUpdatedMembershipDates({ beginDate, endDate, periodMonths, extraPeriods }));

    dispatch(handleFieldChange({ name: "beginDate", value: beginDate }));
    dispatch(handleFieldChange({ name: "endDate", value: endDate }));
    dispatch(clearEndpoint("_api_member_membership_dates"));
};
