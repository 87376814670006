import axios from "axios";
import store from "../store";
import applyAppTokenRefreshInterceptor from "../helpers/applyAppTokenRefreshInterceptor";
import tokenConfig from "../helpers/tokenConfig";
import getStorageItem from "../helpers/getStorageItem";
import { EXPIRED_MFA_CODE, INVALID_EMAIL_OR_PASSWORD, INVALID_MFA_CODE } from "../common/consts";
import { LOGOUT_SUCCESS, REFRESH_TOKEN_SUCCESS, SET_SESSION_TOKEN } from "../actions/types";

const shouldIntercept = error => {
    try {
        if ([INVALID_EMAIL_OR_PASSWORD, INVALID_MFA_CODE, EXPIRED_MFA_CODE].includes(error?.response?.data?.msg)) {
            return false;
        }
        return error.response.status === 401;
    }
    catch (e) {
        return false;
    }
};

const setTokenData = (tokenData = {}) => {
    store.dispatch({ type: REFRESH_TOKEN_SUCCESS, payload: tokenData });
};

const handleTokenRefresh = () => {
    return new Promise((resolve, reject) => {
        const refreshToken = getStorageItem("refreshToken"); // pulling this from storage in case we have two tabs open
        const body = { refreshToken };
        axios.post("/api/auth/refresh", body, tokenConfig(store.getState))
            .then(({ data }) => {
                const tokenData = {
                    accessToken: data.accessToken
                };
                resolve(tokenData);
            })
            .catch(err => {
                store.dispatch({ type: LOGOUT_SUCCESS });
                reject(err);
            });
    });
};

applyAppTokenRefreshInterceptor(axios, { setTokenData, handleTokenRefresh, shouldIntercept });

axios.interceptors.response.use(function(response){
    if (response.data && typeof response.data.sessionToken !== "undefined"){
        store.dispatch({ type: SET_SESSION_TOKEN, payload: response.data.sessionToken });
    }
    return response;
});

export default axios;

// this was created for a special use case, but please use redux actions for all requests
export const tokenizedFetch = ({ apiRoute, body = {}, config = {} }) => {
    config = {
        ...tokenConfig(store.getState),
        ...config
    };
    return new Promise((resolve, reject) => {
        axios
            .post(apiRoute, body, { ...config })
            .then(resp => resolve(resp))
            .catch(err => reject(err));
    });
};
