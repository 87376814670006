import React from "react";
import { connect } from "react-redux";
import Email from "./base/Email";
import { handleFieldChange } from "../../actions/formActions";
import useDefValue from "./useDefValue";

const EmailField = ({ name, handleFieldChange, onChange, ...allOtherProps }) => {
    useDefValue({ ...allOtherProps, name });

    const handleChange = e => {
        handleFieldChange({ name, value: String(e.target.value).trim() });
        if (typeof onChange === "function") onChange(e);
    };

    return <Email {...allOtherProps} name={name} onChange={handleChange} />;
};

const mapsStateToProps = (state, { name }) => {
    return {
        value: state.form.data[name],
        error: state.form.errors[name]
    };
};

const mapDispatchToProps = { handleFieldChange };

export default connect(mapsStateToProps, mapDispatchToProps)(EmailField);
