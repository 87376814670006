import React from "react";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import MaskedInput from "./MaskedInput";

const getMask = val => {
    const digits = String(val).replace(/\D/ig, "");
    if (digits.length === 11) {
        return [/\d/, " ", "(", /[1-9]/, /\d/, /\d/, ")", " ", /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/];
    }
    else if (digits.length === 7) {
        return [/\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/];
    }
    return ["(", /[1-9]/, /\d/, /\d/, ")", " ", /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/];
};

const Phone = ({ name, label, value, helperText, error, onChange, maxLength, ...allOtherProps }) => {
    const {
        autoComplete,
        autoFocus,
        classes,
        className,
        color,
        defaultValue,
        disabled,
        FormHelperTextProps,
        fullWidth = true,
        InputLabelProps,
        InputProps = {},
        inputProps = {},
        inputRef,
        margin,
        onFocus,
        onBlur,
        required,
        rows,
        rowsMax,
        size,
        variant = "outlined"
    } = allOtherProps;

    const eligibleProps = {
        autoComplete,
        autoFocus,
        classes,
        className,
        color,
        defaultValue,
        disabled,
        FormHelperTextProps,
        fullWidth,
        InputLabelProps,
        InputProps,
        inputProps,
        inputRef,
        margin,
        onBlur,
        required,
        rows,
        rowsMax,
        size,
        variant
    };

    return (
        <TextField
            {...eligibleProps}
            id={name}
            name={name}
            label={label}
            inputProps={{ ...inputProps, maxLength, disabled, mask: getMask }}
            InputProps={{ ...InputProps, inputComponent: MaskedInput, onFocus }}
            value={value || ""}
            helperText={`${error || ""}${error && helperText ? " - " : ""}${helperText || ""}`}
            onChange={onChange}
            error={Boolean(error)}
        />
    );
};

Phone.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.any
};

export default React.memo(Phone);
