import React from "react";
import { connect } from "react-redux";
import Checkbox from "./base/Checkbox";
import { handleFieldChange } from "../../actions/formActions";

const CheckboxField = ({ name, handleFieldChange, onChange, ...allOtherProps }) => {
    const handleChange = e => {
        handleFieldChange({ name, value: e.target.checked });
        if (typeof onChange === "function") onChange(e);
    };
    return <Checkbox {...allOtherProps} onChange={handleChange} name={name} />;
};

const mapsStateToProps = (state, { name }) => {
    return {
        checked: state.form.data[name],
        error: state.form.errors[name]
    };
};

const mapDispatchToProps = { handleFieldChange };

export default connect(mapsStateToProps, mapDispatchToProps)(CheckboxField);
