import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button, createMuiTheme, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, makeStyles, ThemeProvider, Typography } from "@material-ui/core";
import { hideDialog } from "../actions/layoutActions";
import { overrides, palette, typography } from "../theme";
import { Close } from "@material-ui/icons";

const theme = createMuiTheme({ overrides, palette, typography });

const defaults = {
    confirmLabel: "Okay",
    cancelLabel: "Cancel",
    showTitleClose: false,
    hideConfirm: false,
    hideCancel: false,
    autoHideOnConfirm: true,
    autoHideOnCancel: true,
    disableBackdropClick: true,
    maxWidth: "sm"
};

const getClasses = makeStyles({
    closeButton: {
        position: "absolute",
        top: 2,
        right: 2
    }
});

const GlobalDialog = ({ open, hideDialog, dialog }) => {
    dialog = { ...defaults, ...dialog || {} };
    const { title, content, confirmCallback, confirmCondition, cancelCallback, confirmLabel, cancelLabel, hideConfirm, hideCancel, maxWidth, buttonProps = {} } = dialog;
    
    const classes = getClasses();

    const handleConfirm = () => {
        if (typeof confirmCallback === "function") {
            if (typeof confirmCondition === "function" && confirmCondition()) {
                confirmCallback();
                if (dialog.autoHideOnConfirm) hideDialog();
            }
            else if (typeof confirmCondition === "undefined") {
                confirmCallback();
                if (dialog.autoHideOnConfirm) hideDialog();
            }
        }
        else if (dialog.autoHideOnConfirm) hideDialog();
    };

    const handleCancel = () => {
        if (typeof cancelCallback === "function") {
            cancelCallback();
        }
        if (dialog.autoHideOnCancel) hideDialog();
    };

    if (!dialog.content) return null;
	
    return (
        <ThemeProvider theme={dialog.theme || theme}>
            <Dialog aria-labelledby="global dialog window" maxWidth={maxWidth} fullWidth open={open} onClose={handleCancel} disableBackdropClick={dialog.disableBackdropClick}>
                <DialogTitle disableTypography className={classes.title}>
                    {typeof title === "string" ? <Typography variant="h3">{title}</Typography> : title}
                    {dialog.showTitleClose && (
                        <IconButton onClick={handleCancel} className={classes.closeButton}>
                            <Close />
                        </IconButton>
                    )}
                </DialogTitle>
                <DialogContent style={{ overflowY: "unset" }}>
                    {
                        typeof content === "string"
                            ? <DialogContentText>{content}</DialogContentText>
                            : <React.Fragment>{content}</React.Fragment>
                    }
                </DialogContent>
                <DialogActions>
                    {!hideConfirm && <Button onClick={handleConfirm} color="primary" autoFocus {...buttonProps}>
                        {confirmLabel}
                    </Button>}
                    {!hideCancel && <Button onClick={handleCancel} color="secondary" {...buttonProps}>
                        {cancelLabel}
                    </Button>}
                </DialogActions>
            </Dialog>
        </ThemeProvider>
    );
};

GlobalDialog.propTypes = {
    dialog: PropTypes.shape({
        title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
        content: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
        confirmLabel: PropTypes.string,
        confirmCallback: PropTypes.func,
        cancelLabel: PropTypes.string,
        cancelCallback: PropTypes.func,
        maxWidth: PropTypes.oneOf(["lg", "md", "sm", "xl", "xs", false])
    })
};

const mapStateToProps = state => ({
    open: state.layout.dialog !== null,
    dialog: state.layout.dialog
});

const mapDispatchToProps = { hideDialog };

export default connect(mapStateToProps, mapDispatchToProps)(GlobalDialog);
