import React from "react";
import { NavLink } from "react-router-dom";
import { Link } from "@material-ui/core";

/**
 * Parses <a> tags from string and converts them to React Components based on link attributes
 * @param {*} string
 * @returns JSX
 */
const parseLinks = string => {
    let match = string.match(/<(a.+?)>(.+?)<\/a>/);
    
    const pieces = [];
    while (match){
        const [wholeMatch, openingTag, innerBody] = match;
		
        const parts = openingTag.split(" ");
        let [, ...attrs] = parts;

        const props = attrs.reduce((obj, attr) => {
            let [name, ...rest] = attr.split("=");
            obj[name] = rest.join("=").replace(/["']/g, "");
            return obj;
        }, {});

        const [begin, end] = string.split(wholeMatch);
        pieces.push(begin);

        if (props.to){
            pieces.push(<Link component={NavLink} {...props}>{innerBody}</Link>);
        }
        else {
            pieces.push(<Link {...props}>{innerBody}</Link>);
        }

        match = end.match(/<(a.+?)>(.+)<\/a>/);
        if (!match) pieces.push(end);
    }
    
    if (pieces.length){
        return pieces.map((piece, i) => (
            <React.Fragment key={i}>{piece}</React.Fragment>
        ));
    }

    return string;
};

export default parseLinks;
