import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";
import { createBrowserHistory } from "history";
import { routerMiddleware } from "connected-react-router";
import { getStorageItem, setStorageItem } from "../helpers";
import { debounce } from "lodash";
import createRootReducer from "../reducers";

export const history = createBrowserHistory();

let store = null;
if (process.env.NODE_ENV === "production") {
    store = createStore(createRootReducer(history), applyMiddleware(routerMiddleware(history), thunk));
}
else {
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    store = createStore(createRootReducer(history), composeEnhancers(applyMiddleware(routerMiddleware(history), thunk)));
}

const getRelevantState = state => {
    const {
        auth: { accessToken, refreshToken, posToken, mfaToken, serviceGroup, sessionToken },
        list: { rowsPerPage },
        layout: { navOpen }
    } = state;

    return {
        accessToken,
        refreshToken,
        posToken,
        mfaToken,
        sessionToken,
        serviceGroup: JSON.stringify(serviceGroup),
        rowsPerPage,
        navOpen
    };
};

const updateLocalStorage = (key, value) => {
    if (getStorageItem(key) !== value) {
        setStorageItem(key, value);
    }
};

const handleStorageUpdates = () => {
    const state = store.getState();
    const relevantState = getRelevantState(state);

    Object.keys(relevantState).forEach(key => {
        updateLocalStorage(key, relevantState[key]);
    });
};

const debouncedHandleStorageUpdates = debounce(handleStorageUpdates, 300);

store.subscribe(debouncedHandleStorageUpdates);

export default store;
