import React from "react";
import PropTypes from "prop-types";
import { FormControl, FormControlLabel, FormHelperText, FormLabel, makeStyles, Checkbox as MuiCheckbox } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    helper: {
        margin: 0,
        marginTop: -10,
        paddingLeft: theme.spacing(4)
    },
    field: {
        marginLeft: theme.spacing(.5)
    },
    checkbox: props => ({
        ...props.styles?.checkbox
    }),
    label: props => ({
        ...props.styles?.label
    })
}));

const Checkbox = ({ legend, label, color = "primary", checked = false, onChange, error, helperText, disabled, size = "medium", stylesByName: styles = {} }) => {
    const classes = useStyles({ styles });
    return (
        <FormControl error={Boolean(error)} className={classes.field}>
            {legend ? <FormLabel component="legend">{legend}</FormLabel> : null}
            <FormControlLabel control={
                <MuiCheckbox size={size} color={color} checked={Boolean(checked)} disabled={disabled} classes={{ root: classes.checkbox }} />
            } label={label} onChange={onChange} classes={{ label: classes.label }} />
            {error || helperText ? <FormHelperText className={classes.helper}>{error || ""}{error && helperText ? " - " : ""}{helperText || ""}</FormHelperText> : null}
        </FormControl>
    );
};

Checkbox.propTypes = {
    label: PropTypes.string.isRequired
};

export default React.memo(Checkbox);
