import { CLEAR_ERRORS, GET_ERRORS } from "./types";

export const returnErrors = (err, type) => {
    const { response } = err;
    
    if (response?.data){
        const { data, status, statusText, ...other } = response || {};
        return {
            type: GET_ERRORS,
            payload: { type, status, statusText, msg: data?.msg, misc: other }
        };
    }

    return { type: GET_ERRORS, payload: err };
};

export const clearErrors = () => {
    return {
        type: CLEAR_ERRORS
    };
};
