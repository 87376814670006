import React from "react";
import PropTypes from "prop-types";
import MaskInput from "react-text-mask";

const MaskedInput = props => {
    const { inputRef, mask, placeholderChar = "\u2000", showMask = false, ...other } = props;
    return (
        <MaskInput
            {...other}
            ref={ref => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={mask}
            placeholderChar={placeholderChar}
            showMask={showMask}
        />
    );
};

MaskedInput.propTypes = {
    mask: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.func]).isRequired
};

export default React.memo(MaskedInput);
