import React from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Alert, AlertTitle } from "@material-ui/lab";
import { Backdrop, IconButton, Typography } from "@material-ui/core";
import { Close, Refresh } from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1000
    },
    title: {
        textTransform: "uppercase"
    },
    err: {
        maxWidth: 800,
        position: "relative",
        padding: theme.spacing(3)
    },
    misc: {
        marginTop: theme.spacing(2),
        wordBreak: "break-word"
    },
    pre: {
        whiteSpace: "pre-wrap",
        wordWrap: "break-word"
    },
    close: {
        position: "absolute",
        top: 0,
        right: 0,
        zIndex: theme.zIndex.drawer + 1001
    }
}));

const Error = () => {
    const error = useSelector(state => state.error);
    const { type, status, statusText, msg, misc } = error;
    const [hide, setHide] = React.useState(null);
    const classes = useStyles();
    const open = React.useMemo(() => !hide && Boolean(status) && ![401, 403].includes(Number(status)), [hide, status]);
    
    React.useEffect(() => {
        function handleEscapeKey(e) {
            if (open && e.key === "Escape") {
                setHide(true);
            }
        }
        window.addEventListener("keyup", handleEscapeKey);
        return () => window.removeEventListener("keyup", handleEscapeKey);
    }, [open]);

    return (
        <Backdrop className={classes.backdrop} open={open} >
            <Alert severity="error" className={classes.err}>
                <IconButton aria-label="close" color="inherit" className={classes.close} onClick={setHide}>
                    <Close />
                </IconButton>
                {msg === "Authorizaton denied" ?
                    <React.Fragment >
                        <AlertTitle className={classes.title}>Authorizaton Error</AlertTitle>
                        <Typography paragraph variant="subtitle2">We apologize for any inconvenience, but it looks like you do not have
                            the proper security enabled to access this module. Please contact eServices support or your system administrator.
                        </Typography>
                        <Typography paragraph variant="subtitle2">
                            Please note, we recommend refreshing the page to remove any after effects of this error. Refreshing can be done
                            by pressing the F5 key on your keyboard or by clicking the <Refresh fontSize="inherit" /> button on your browser.
                        </Typography>
                    </React.Fragment> :
                    process.env.NODE_ENV === "production" ?
                        <React.Fragment>
                            <AlertTitle className={classes.title}>An error has occurred</AlertTitle>
                            <Typography paragraph variant="subtitle2">We apologize for any inconvenience, but an error has occurred. Our engineering
                                team has been notified but we encourage you to refresh the page and try again.
                                If the error continues and you need immediate assistance then please feel free to contact our support team.
                            </Typography>
                            <Typography paragraph variant="subtitle2">
                                Please note, we recommend refreshing the page to remove any after effects of this error. Refreshing can be done
                                by pressing the F5 key on your keyboard or by clicking the <Refresh fontSize="inherit" /> button on your browser.
                            </Typography>
                        </React.Fragment> :
                        <React.Fragment>
                            <AlertTitle className={classes.title}>{`${statusText} Error`}</AlertTitle>
                            <Typography variant="subtitle1">There was an error thrown during the previous execution</Typography>
                            <Typography variant="subtitle1">{type && `Execution Type: ${type}`}</Typography>
                            <Typography variant="h5">{msg && msg}</Typography>
                            <div className={classes.misc}>{misc && (typeof misc === "object" ?
                                <pre className={classes.pre}>{JSON.stringify(misc, undefined, 4)}</pre> : misc)}</div>
                        </React.Fragment>
                }
            </Alert>
        </Backdrop>
    );
};

export default React.memo(Error);
