import React from "react";
import { Redirect, useLocation } from "react-router-dom/cjs/react-router-dom";

const RedirectTrailingSlash = () => {
    const location = useLocation();
    const hasTrailingSlash = location?.pathname?.length > 1 && location?.pathname?.endsWith("/");
    if (hasTrailingSlash) {
        return <Redirect to={location.pathname.slice(0, -1)} />;
    }
    return null;
};

export default RedirectTrailingSlash;
