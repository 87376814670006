import { useMediaQuery, useTheme } from "@material-ui/core";

function useBreakpoint(){
    const theme = useTheme();
    
    const xl = useMediaQuery(theme.breakpoints.only("xl"));
    const lg = useMediaQuery(theme.breakpoints.only("lg"));
    const md = useMediaQuery(theme.breakpoints.only("md"));
    const sm = useMediaQuery(theme.breakpoints.only("sm"));

    if (xl) return "xl";
    if (lg) return "lg";
    if (md) return "md";
    if (sm) return "sm";
	
    return "xs";
}

export default useBreakpoint;
