import axios from "../common/axios";
import { push } from "connected-react-router";
import { returnErrors } from "./errorActions";
import tokenConfig from "../helpers/tokenConfig";
import {
    CLEAR_ENDPOINT,
    END_POINT_FAIL,
    END_POINT_START,
    END_POINT_SUCCESS,
    GLOBAL_DIALOG_HIDE,
    GLOBAL_DIALOG_SHOW,
    NOTIFICATION_REMOVE_ALL,
    NOTIFICATION_REMOVED,
    NOTIFICATION_VIEW_ALL,
    NOTIFICATION_VIEWED,
    NOTIFICATIONS_HIDE,
    NOTIFICATIONS_SHOW,
    NOTIFICATIONS_SUCCESS,
    PRINT_DONE,
    PRINT_START,
    RECENT_ACTIVITY_HIDE,
    RECENT_ACTIVITY_SHOW,
    RECENT_ACTIVITY_SUCCESS,
    SNACK_BAR_ADD,
    SNACK_BAR_PROCESS,
    TOGGLE_NAV
} from "./types";

export const toggleNav = () => {
    return {
        type: TOGGLE_NAV
    };
};

export const setCurrentRoute = path => dispatch => {
    dispatch(push(path));
};

export const showDialog = props => {
    return {
        type: GLOBAL_DIALOG_SHOW,
        payload: props
    };
};

export const hideDialog = () => {
    return {
        type: GLOBAL_DIALOG_HIDE
    };
};

export const addSnackbar = (snack, action) => {
    return {
        type: SNACK_BAR_ADD,
        payload: { snack, action }
    };
};

export const processSnack = () => {
    return {
        type: SNACK_BAR_PROCESS
    };
};

export const notificationsHide = () => {
    return { type: NOTIFICATIONS_HIDE };
};

export const notificationsShow = () => {
    return { type: NOTIFICATIONS_SHOW };
};

export const notificationViewed = notificationID => (dispatch, getState) => {
    dispatch({ type: NOTIFICATION_VIEWED, payload: notificationID });
    axios
        .post("/api/general/notification/viewed", { filters: { notificationID } }, tokenConfig(getState))
        .catch(err => {
            dispatch(returnErrors(err, `NOTIFICATION UPDATE FAILED`));
        });
};

export const notificationRemoved = notificationID => (dispatch, getState) => {
    dispatch({ type: NOTIFICATION_REMOVED, payload: notificationID });
    axios
        .post("/api/general/notification/removed", { filters: { notificationID } }, tokenConfig(getState))
        .catch(err => {
            dispatch(returnErrors(err, `NOTIFICATION UPDATE FAILED`));
        });
};

export const notificationViewAll = () => (dispatch, getState) => {
    dispatch({ type: NOTIFICATION_VIEW_ALL });
    axios
        .post(`/api/general/notification/viewall`, {}, tokenConfig(getState))
        .catch(err => {
            dispatch(returnErrors(err, `NOTIFICATION UPDATE FAILED`));
        });
};

export const notificationRemoveAll = () => (dispatch, getState) => {
    dispatch({ type: NOTIFICATION_REMOVE_ALL });
    axios
        .post(`/api/general/notification/removeall`, {}, tokenConfig(getState))
        .catch(err => {
            dispatch(returnErrors(err, `NOTIFICATION UPDATE FAILED`));
        });
};

export const recentActivityHide = () => {
    return { type: RECENT_ACTIVITY_HIDE };
};

export const recentActivityShow = () => {
    return { type: RECENT_ACTIVITY_SHOW };
};
export const clearEndpoint = endpoint => {
    return { type: CLEAR_ENDPOINT, payload: endpoint.replaceAll("/", "_") };
};

export const callEndpoint = (endpoint, body, secure = true, clear = true) => (dispatch, getState) => {
    if (clear) clearEndpoint(endpoint);
    const key = endpoint.replace(/\//g, "_");
    dispatch({ type: END_POINT_START, payload: { key } });
    axios
        .post(endpoint, body, tokenConfig(getState, secure))
        .then(res => {
            dispatch({
                type: END_POINT_SUCCESS,
                payload: {
                    key,
                    data: res.data
                }
            });
        })
        .catch(err => {
            dispatch(returnErrors(err, `${endpoint.toUpperCase()} FAILED`));
            dispatch({
                type: END_POINT_FAIL,
                payload: { key }
            });
        });
};

export const callEndpointV2 = ({ endpoint, body, secure = true, clear = true, callback }) => (dispatch, getState) => {
    if (clear) clearEndpoint(endpoint);
    const key = endpoint.replace(/\//g, "_");
    dispatch({ type: END_POINT_START, payload: { key } });
    axios
        .post(endpoint, body, tokenConfig(getState, secure))
        .then(res => {
            if (typeof callback === "function"){
                callback(res?.data);
            }
            dispatch({
                type: END_POINT_SUCCESS,
                payload: {
                    key,
                    data: res.data
                }
            });
        })
        .catch(err => {
            if (typeof callback === "function") {
                callback(err?.response?.data);
            }
            dispatch(returnErrors(err, `${endpoint.toUpperCase()} FAILED`));
            dispatch({
                type: END_POINT_FAIL,
                payload: { key }
            });
        });
};

export const getNotifications = () => (dispatch, getState) => {
    /**
     * this is the only axios call (besides REFRESH) using the refreshToken
     * allowing multiple notification requests without token failures
     */
    const refreshToken = getState().auth.refreshToken;
    if (!refreshToken) return;
    
    const config = {
        headers: {
            "Content-Type": "application/json"
        }
    };
    config.headers["authorization"] = `Bearer ${refreshToken}`;
    axios
        .post("/api/general/notification", {}, config)
        .then(res => {
            dispatch({
                type: NOTIFICATIONS_SUCCESS,
                payload: res.data
            });
        })
        .catch(err => {
            if (err?.response?.data?.msg === "Token session has expired") {
                dispatch(returnErrors(err));
            }
            console.log(err?.message);
        });
};

export const getRecentActivity = () => (dispatch, getState) => {
    axios
        .post("/api/general/recentviews", {}, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: RECENT_ACTIVITY_SUCCESS,
                payload: res.data
            });
        })
        .catch(err => {
            dispatch(returnErrors(err));
        });
};

export const printAction = ({ url, secure = true, filters = {}, body = {}, autoPrint = true }) => (dispatch, getState) => {
    dispatch({ type: PRINT_START });
    body = { ...body, filters };
    axios
        .post(url, body, tokenConfig(getState, secure))
        .then(res => {
            dispatch({ type: PRINT_DONE });
            const w = window.open("", "print_window", "height=600,width=800");
            if (w) {
                w.document.open();
                w.document.write(res.data);
                w.document.close();
                w.focus();
                if (autoPrint) setTimeout(() => w.print(), 500);
            }
            else {
                alert("Please allow popups for this site and then refresh your page and try again.");
            }
        })
        .catch(err => {
            dispatch({ type: PRINT_DONE });
            dispatch(returnErrors(err));
        });
};

export const printReceiptAction = ({ url, secure = true, filters = {} }) => (dispatch, getState) => {
    dispatch({ type: PRINT_START });
    const body = { filters };
    axios
        .post(url, body, tokenConfig(getState, secure))
        .then(res => {
            axios
                .post("http://localhost:4000/print", res.data)
                .then(res => {
                    if (res.data.error) {
                        dispatch(addSnackbar(`Error: ${res.data.error}`));
                    }
                    dispatch({ type: PRINT_DONE });
                });
        })
        .catch(err => {
            dispatch({ type: PRINT_DONE });
            dispatch(returnErrors(err));
        });
};
