/**
 * tokenConfig
 * @param getState state tree from redux store
 */
const tokenConfig = (getState, useToken = true) => {
    const accessToken = getState().auth.accessToken;
    const sessionToken = getState().auth.sessionToken;
    const posToken = getState().auth.posToken;
    const mfaToken = getState().auth.mfaToken;

    const config = {
        headers: {
            "Content-Type": "application/json"
        }
    };

    if (accessToken && useToken) {
        config.headers["authorization"] = `Bearer ${accessToken}`;
    }

    if (sessionToken){
        config.headers["session"] = sessionToken;
    }

    if (posToken) {
        config.headers["postoken"] = posToken;
    }

    if (mfaToken) {
        config.headers["mfatoken"] = mfaToken;
    }
	
    return config;
};

export default tokenConfig;
