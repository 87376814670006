import Cookies from "js-cookie";
import { format as dateFnsFormat, isValid, parse, parseISO } from "date-fns";
import format, { types } from "./format";

export function shallowEqual(object1, object2) {
    object1 = object1 || {};
    object2 = object2 || {};

    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);

    if (keys1.length !== keys2.length) {
        return false;
    }

    for (let key of keys1) {
        if (object1[key] !== object2[key]) {
            return false;
        }
    }

    return true;
}

export function cleanRegex(regex) {
    return regex.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}

export function deepEqual(object1, object2) {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);

    if (keys1.length !== keys2.length) {
        return false;
    }

    for (const key of keys1) {
        const val1 = object1[key];
        const val2 = object2[key];
        const areObjects = isObject(val1) && isObject(val2);
        if ((areObjects && !deepEqual(val1, val2)) || (!areObjects && val1 !== val2)) {
            return false;
        }
    }

    return true;
}

export function round(num, places = 2){
    const multiplier = Number(`1${"0".repeat(places)}`);
    return Math.round((num + Number.EPSILON) * multiplier) / multiplier;
}

export function isObject(object) {
    return object !== null && typeof object === "object";
}

export function isNumeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
}

export function isDate(value, formatString = "MM/dd/yyyy") {
    if (!value) return false;
    const parsedDate = parse(value, formatString, new Date());
    return isValid(parsedDate) && value === dateFnsFormat(parsedDate, formatString);
}

export function isJSON(str) {
    if (typeof str !== "string") return false;
    if (str.trim()[0] !== "{" || str.trim()[str.trim().length - 1] !== "}") return false;
    try {
        JSON.parse(str);
        return true;
    }
    catch (e) {
        return false;
    }
}

export function isValidDate(dateString) {
    let parsedDate = null;

    try {
        // try to parse using the custom format function
        parsedDate = new Date(format(types.DATE, dateString));
    }
    catch (customParseError) {
        parsedDate = null;
    }

    // if custom parsing fails or results in an invalid date
    if (!parsedDate || isNaN(parsedDate)) {
        try {
            // try to parse as ISO 8601 format
            parsedDate = parseISO(dateString);
        }
        catch (isoError) {
            parsedDate = null;
        }
    }

    // if ISO parsing also fails, try custom formats
    if (!parsedDate || isNaN(parsedDate)) {
        const formats = ["MM-dd-yyyy", "M-d-yyyy", "yyyy-MM-dd", "yyyy-M-d", "MM/dd/yyyy", "M/d/yyyy", "dd-MMM-yyyy", "d-MMM-yyyy"];
        for (let format of formats) {
            try {
                parsedDate = parse(dateString, format, new Date());
                if (isValid(parsedDate)) break;
            }
            catch (formatError) {
                parsedDate = null;
            }
        }
    }

    if (parsedDate && isValid(parsedDate)) {
        return true;
    }
    return false;
}

export function isLocalhost() {
    return Boolean(
        window.location.hostname.includes("localhost") ||
		// [::1] is the IPv6 localhost address.
		window.location.hostname === "[::1]" ||
		// 127.0.0.0/8 are considered localhost for IPv4.
		window.location.hostname.match(
		    /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
		)
    );
}

export function getRowLetters(length) {
    let rows = {};
    for (let i = 1; i <= length; i++){
        let width = Math.floor(i / 27);
        let row = i - (26 * width);
        let letter = (row + 9).toString(36).toUpperCase();
        rows[i] = letter.repeat(width + 1);
    }
    return rows;
}

export function rowLetter(row) {
    if (row) {
        const data = getRowLetters(row);
        return data[row];
    }
    return row;
}

export const camelToTitle = text => {
    if (text) {
        const result = text.replace(/([A-Z]+)/g, " $1").replace(/([A-Z][a-z])/g, "$1");
        return result.charAt(0).toUpperCase() + result.slice(1);
    }
    return text;
};

export const getEndpointKey = endpoint => {
    return endpoint ? endpoint.replace(/\//g, "_") : endpoint;
};

export const isIOS = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipod/.test(userAgent);
};

export const isAndroid = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /android/.test(userAgent);
};

export const isMobile = () => {
    return isIOS() || isAndroid();
};

export function getCookie(name) {
    const cookie = Cookies.get(name);
    if (cookie){
        if (isJSON(cookie)) return JSON.parse(cookie);
        return cookie;
    }
    return;
}

export const hasHTMLElements = str => {
    const doc = new DOMParser().parseFromString(str, "text/html");
    return Array.from(doc.body.childNodes).some(node => node.nodeType === 1);
};

const utils = {
    shallowEqual,
    deepEqual,
    round,
    isObject,
    isNumeric,
    isDate,
    isLocalhost,
    getRowLetters,
    getCookie,
    rowLetter
};

export default utils;
