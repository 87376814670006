import React from "react";
import { isIE, isOpera } from "react-device-detect";

const BrowserCheck = props => {
    if (isIE || isOpera) {
        return (
            <div style={{ fontFamily: "roboto,arial,helvetica,serif", width: "600px", margin: "20px auto 0" }}>
                <h1>Whoops, your browser is not supported by this website</h1>
                <p style={{ lineHeight: "26px" }}>
                    For a safer, faster, and better user experience, please use a modern browser like
                    <a style={{ padding: "5px" }} href="https://www.google.com/chrome/">Chrome</a>,
                    <a style={{ padding: "5px" }} href="https://www.mozilla.org/en-US/exp/firefox/">Firefox</a>,
                    <a style={{ padding: "5px" }} href="https://www.apple.com/safari/">Safari</a>, or
                    <a style={{ padding: "5px" }} href="https://www.microsoft.com/en-us/edge">Edge</a>
                </p>
            </div>
        );
    }
    return props.children;
};

export default BrowserCheck;
