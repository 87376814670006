import React from "react";
import { connect } from "react-redux";
import NativeSelect from "./base/NativeSelect";
import { handleFieldChange } from "../../actions/formActions";

const NativeSelectField = ({ name, handleFieldChange, onChange, ...allOtherProps }) => {
    const handleChange = e => {
        handleFieldChange({ name, value: e.target.value });
        if (typeof onChange === "function") onChange(e);
    };

    return <NativeSelect {...allOtherProps} name={name} onChange={handleChange} />;
};

const mapsStateToProps = (state, { name, options }) => {
    return {
        value: state.form.data[name],
        error: state.form.errors[name],
        options: options?.length ? options : state.form.dropdowns[name]
    };
};

const mapDispatchToProps = { handleFieldChange };

export default connect(mapsStateToProps, mapDispatchToProps)(NativeSelectField);
