import React from "react";
import { handleFieldChange } from "../../actions/formActions";
import { useDispatch } from "react-redux";

const useDefValue = ({ defValue, value, name }) => {
    const dispatch = useDispatch();
    
    React.useEffect(() => {
        if (!value) {
            if (defValue) {
                dispatch(handleFieldChange({ name, value: defValue }));
            }
        }
    }, [name, defValue, value, dispatch]);
};

export default useDefValue;
