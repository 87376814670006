import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Card, CardMedia, TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { CameraAlt, Crop as CropIcon, Delete, Image as ImageIcon } from "@material-ui/icons";
import { CLOUDINARY_URL } from "../../common/consts";
import { addValidationError, handleFieldChange, removeValidationError, uploadFileToServer } from "../../actions/formActions";
import Loader from "../../components/Loader";
import WebcamCapture from "../WebcamCapture";
import ImageCropper from "../ImageCropper";
import { hideDialog, showDialog } from "../../actions/layoutActions";

const useStyles = makeStyles(theme => ({
    input: {
        display: "none"
    },
    imageIcon: {
        flex: 1,
        display: "flex"
    },
    fileName: {
        padding: theme.spacing(2),
        display: "inline-block",
        flex: 1
    },
    media: {
        height: style => style.height || "auto",
        width: style => style.width || "auto",
        maxWidth: style => style.maxWidth || "100%",
        maxHeight: style => style.maxHeight || "100%"
    },
    card: {
        overflow: "visible",
        position: "relative",
        minHeight: 50,
        padding: theme.spacing(.5),
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    label: {
        padding: theme.spacing(0, .5),
        color: theme.palette.text.secondary,
        fontSize: 13,
        position: "absolute",
        background: "white",
        top: -7,
        left: 8,
        lineHeight: "12px"
    },
    button: {
        position: "absolute",
        top: -1,
        right: 0,
        borderRadius: 0,
        borderBottomLeftRadius: 3,
        borderTopRightRadius: 3
    }
}));

const validateImageRatio = async function(file, requiredRatio) {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => {
            const { width, height } = img;
            const [requiredWidth, requiredHeight] = requiredRatio.split(":");
            if (Number(requiredWidth) === 0 || Number(requiredHeight) === 0) {
                reject("Invalid ratio provided. Width and height must be non-zero.");
            }
            const requiredRatioCalculation = Number(requiredWidth) / Number(requiredHeight);
            const actualRatio = width / height;
            if (Math.abs(actualRatio - requiredRatioCalculation) > 0.1) { // room for small margin of error
                reject(`Image aspect ratio doesn't match the required ratio of ${requiredRatio}. Try using the crop tool.`);
            }
            else {
                resolve("Image validated successfully");
            }
            URL.revokeObjectURL(img.src); // Revoking the object URL
        };
        img.onerror = () => {
            reject("Image loading failed");
        };
        img.src = URL.createObjectURL(file); // Using createObjectURL
    });
};

const ImageInputComponent = React.memo(({ name, requiredRatio, useWebcam }) => {
    const uploading = useSelector(state => state.form.uploading);
    const dispatch = useDispatch();
    const classes = useStyles();
    const [clicked, setClicked] = React.useState(false);

    React.useEffect(() => {
        if (clicked && !uploading) setClicked(false);
    }, [clicked, uploading]);

    const handleUpload = async e => {
        if (e.target.files && e.target.files.length){
            const file = e.target.files[0];
            let imageValid = true;
            if (requiredRatio) {
                dispatch(removeValidationError(name));
                imageValid = false;
                try {
                    await validateImageRatio(file, requiredRatio);
                    imageValid = true;
                }
                catch (error) {
                    dispatch(addValidationError(name, error));
                }
            }
            if (imageValid) {
                dispatch(uploadFileToServer({ route: "/api/image/upload", fieldName: name, data: file }));
                setClicked(true);
            }
        }
    };

    const showWebCamDialog = e => {
        e.preventDefault();
        dispatch(showDialog({
            content: <WebcamCapture name={name} />,
            hideConfirm: true,
            title: "Capture Image with Webcam",
            hideCancel: true
        }));
    };

    const showCrop = e => {
        e.preventDefault();
        dispatch(showDialog({
            title: "Upload and Crop Image",
            content: <ImageCropper name={name}
                requiredRatio={requiredRatio}
                onDone={file => dispatch(uploadFileToServer({ route: "/api/image/upload", fieldName: name, data: file }))}
                onClose={() => dispatch(hideDialog())}
            />,
            hideConfirm: true,
            hideCancel: true
        }));
    };

    return (
        <React.Fragment>
            <input accept="image/*, video/mp4" className={classes.input} id={`image_upload_${name}`} multiple type="file" onChange={handleUpload} />
            <label htmlFor={`image_upload_${name}`} className={classes.imageIcon}>
                {clicked && uploading ?
                    <Box pt={5} pb={5} width="100%">
                        <Loader size={30} />
                    </Box> :
                    <React.Fragment>
                        <Button title="Click to Upload Image" component="span" color="primary" size="large">
                            <ImageIcon />
                        </Button>
                        <Typography variant="body1" className={classes.fileName}>
                            Click to upload a file
                        </Typography>
                        {useWebcam ?
                            <Button title="Webcam - Click to take a picture" component="span" color="primary" size="large" onClick={showWebCamDialog}>
                                <CameraAlt />
                            </Button> : null}
                        {requiredRatio ?
                            <Button title="Crop tool - Click to upload and crop" component="span" color="primary" size="large" onClick={showCrop}>
                                <CropIcon />
                            </Button> : null}
                    </React.Fragment>
                }
            </label>
        </React.Fragment>
    );
});

const ImageField = ({ name, label, helperText, style = {}, useWebcam, requiredRatio, removeImage = true }) => {
    const image = useSelector(state => state.form.data[name]);
    const error = useSelector(state => state.form.errors[name]);
    const classes = useStyles(style);
    const dispatch = useDispatch();

    /**
	 * Logic built so that images are objects, e.g. { cloudinaryID:12, name:"test", size:122 }
	 * We only store image ID string in the database and following useMemo converts ID string into object before rendering
	 */
    const imageObj = React.useMemo(() => {
        if (typeof image !== "object") return { cloudinaryID: image };
        return image || {};
    }, [image]);

    const { cloudinaryID } = imageObj;

    const handleRemove = () => dispatch(handleFieldChange({ name, value: "" }));
	
    if (cloudinaryID && cloudinaryID.search) {
        // VIDEOS
        if (cloudinaryID.search(/\.mp4/i) >= 0){
            const videoPath = `${CLOUDINARY_URL}/video/upload/${cloudinaryID}`;
            return (
                <Card variant="outlined" className={classes.card}>
                    <Typography className={classes.label}>{label}</Typography>
                    <CardMedia component="video" height="500" src={videoPath} classes={{ media: classes.media }} />
                    <Button size="small" color="secondary" onClick={handleRemove} variant="contained" disableElevation className={classes.button}>
                        <Delete /> Remove Video
                    </Button>
                </Card>
            );
        }
        // IMAGES
        else {
            let width = 1000;
            let height = 700;
            if (requiredRatio) {
                ({ width, height } = requiredRatio.cloudinaryRender);
            }
            const imgPath = `${CLOUDINARY_URL}/image/upload/c_limit,w_${width},h_${height}/q_70/${cloudinaryID}`;
            return (
                <Card variant="outlined" className={classes.card}>
                    <Typography className={classes.label}>{label}</Typography>
                    <CardMedia component="img" alt="" height="500" image={imgPath} classes={{ media: classes.media }} />
                    {removeImage === true ? (
                        <Button size="small" color="secondary" onClick={handleRemove} variant="contained" disableElevation className={classes.button}>
                            <Delete /> Remove Image
                        </Button>
                    ) : null}
                </Card>
            );
        }
    }

    return (
        <TextField
            name={name}
            id={name}
            variant="outlined"
            label={label}
            fullWidth
            InputLabelProps={{ shrink: true }}
            helperText={error || helperText}
            inputProps={{ name, useWebcam, requiredRatio: requiredRatio?.ratio }}
            InputProps={{
                inputComponent: ImageInputComponent
            }}
            FormHelperTextProps={{ error: error ? true : false }}
        />
    );
};

export default React.memo(ImageField);
