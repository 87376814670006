import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { CircularProgress, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Search } from "@material-ui/icons";
import { getDropdownOptions, handleFieldChange } from "../../actions/formActions";
import debounce from "../../helpers/debounce";
import { cleanRegex } from "../../helpers/utils";

const useStyles = makeStyles(theme => ({
    group: {
        fontWeight: "bold",
        color: theme.palette.primary.main
    }
}));

const SearchField = ({
    label, 					// label of field
    name, 					// name of field and gets used to set selected value e.g. form[name] = "selected value"
    value, 					// the current value of this field
    dropdownEndpoint,		// name of dropdownDAL endpoint used to query records, if blank, will use the above [name] prop
    options,				// records return from endpoint  [{donorID, name, value, label}, {donorID, name, value, label}] *do not manually set this
    optVal,					// the value key saved from selected option
    optName,				// the label key shown from selected option
    onChange,				// optional ohChange handler onchange(event,selectedOption)
    customEndpoint,			// a way to use a custom endpoint, setting this for public forms
    endpointFilters = {},	// used to pass filters into endpoint
    textClasses = {},
    placeholder,
    getDropdownOptions,
    handleFieldChange,
    error,
    maxLength,
    autoComplete,
    helperText,
    renderOption,
    getOptionLabel
}) => {
    const classes = useStyles();
    const [loading, setLoading] = React.useState(false);
    const endPoint = dropdownEndpoint || name;
	
    React.useEffect(() => {
        setLoading(false);
    }, [options]);

    const handleChange = (e, option) => {
        const value = option && option[optVal] ? option[optVal] : "";
        handleFieldChange({ name, value });
        if (typeof onChange === "function") onChange(e, option);
    };
	
    const handleSearch = debounce((event, searchTerm, reason) => {
        if (!event) return;
        if (reason === "reset") return;
        if (searchTerm?.length > 1){
            setLoading(true);
            getDropdownOptions({ name, dropdownEndpoint: endPoint, filters: { ...endpointFilters, [endPoint]: searchTerm }, customEndpoint });
        }
    }, 350);

    const selected = React.useMemo(() => {
        if (Array.isArray(options)) {
            return options.find(f => String(f[optVal]) === String(value));
        }
        return null;
    }, [optVal, options, value]);
    
    return (
        <Autocomplete
            value={selected || null}
            clearOnEscape
            onChange={handleChange}
            onInputChange={handleSearch}
            getOptionSelected={(option, value) => option[optVal] === value[optVal]}
            getOptionLabel={getOptionLabel && typeof getOptionLabel === "function" ? getOptionLabel : option => option[optName] || ""}
            groupBy={option => option.type}
            options={options || []}
            loading={loading}
            classes={{
                groupLabel: classes.group
            }}
            renderOption={renderOption && typeof renderOption === "function" ? renderOption : (option, { inputValue }) => {
                const __html = String(option[optName]).replace(new RegExp(`(${cleanRegex(inputValue)})`, "gi"), `<span style="font-weight:500;">$1</span>`);
                return <span dangerouslySetInnerHTML={{ __html }} />;
            }}
            filterOptions={x => x} // disable default filtering. Similar issue specified here: https://github.com/mui/material-ui/issues/20068. v5 docs mention this as well: https://mui.com/material-ui/react-autocomplete/#search-as-you-type
            renderInput={params => (
                <TextField
                    {...params}
                    label={label}
                    classes={textClasses}
                    variant="outlined"
                    placeholder={placeholder}
                    helperText={`${error || ""}${error && helperText ? " - " : ""}${helperText || ""}`}
                    error={Boolean(error)}
                    autoComplete={autoComplete}
                    inputProps={{ ...params.inputProps, maxLength }}
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: <Search />,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        )
                    }}
                />
            )}
        />
    );
};

SearchField.defaultProps = {
    optVal: "value",
    optName: "label"
};

SearchField.propTypes = {
    name: PropTypes.string.isRequired,
    customProp: function(props, propName, componentName) {
        const { options, optVal } = props;
        if (options && options.length){
            const [test] = options;
            if (!([optVal] in test)){
                return new Error(`Invalid prop \`optVal\` supplied to \`${componentName}\`, \`options[0][optVal]\` does not exist.`);
            }
        }
    }
};

const mapStateToProps = (state, { name }) => ({
    options: state.form.dropdowns[name],
    value: state.form.data[name],
    error: state.form.errors[name]
});

const mapDispatchToProps = { getDropdownOptions, handleFieldChange };

export default connect(mapStateToProps, mapDispatchToProps)(SearchField);

