const getStorageItem = (key, session = false) => {
    const storage = session ? window.sessionStorage : window.localStorage;
    try {
        const value = storage.getItem(key);
        if (!value) return;
        return value;
    }
    catch (e) {
        console.log("Issue getting local storage", e);
        return;
    }
};

export default getStorageItem;
