import Cookies from "js-cookie";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { handleFieldChange } from "../actions/formActions";

const useClientToken = function() {
    const dispatch = useDispatch();
    const utm_source = Cookies.get("utm_source");
	
    useEffect(() => {
        if (utm_source) {
            const urlObj = new URL(utm_source);
            const params = new URLSearchParams(urlObj.search);
            const value = params.get("utm_medium");
            const sources = value.split("").map((letter, i) => ({ source_code: letter, src: `/js/l${i}` }));

            dispatch(handleFieldChange({ name: "sources", value: sources }));
        }
        return () => Cookies.remove("utm_source");
    }, [dispatch, utm_source]);
};

export default useClientToken;
