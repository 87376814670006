import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { callEndpoint } from "../actions/layoutActions";

const useEventSessionFetch = serviceGroupID => {
    const dispatch = useDispatch();

    const offers = useSelector(state => state.shop.offers);
    const eventType = useSelector(state => state.shop.eventType);

    useEffect(() => {
        // Check if eventType exists and its private property is true
        const isEventTypePrivate = eventType && eventType.private;

        // Check if offers exist and at least one of them has a private property set to true
        const isOfferPrivate = Array.isArray(offers) && offers.some(offer => offer.private);

        if (serviceGroupID && (isEventTypePrivate || isOfferPrivate)) {
            dispatch(callEndpoint("/api/public/event/sessions", { filters: { serviceGroupID } }));
        }
    }, [serviceGroupID, offers, eventType, dispatch]);
	
};

export default useEventSessionFetch;
