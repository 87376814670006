import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Box, ButtonBase, Checkbox, Divider, Grid, List, ListItem, ListItemIcon, ListItemText, makeStyles, Typography } from "@material-ui/core";
import { handleFieldChange } from "../../actions/formActions";

const getStyles = makeStyles(theme => ({
    listItem: {
        paddingTop: 0,
        paddingBottom: 0
    },
    gridItem: {
        backgroundColor: theme.palette.common.white,
        outline: `1px solid ${theme.palette.divider}`,
        paddingBottom: theme.spacing(1)
    },
    container: {
        backgroundColor: theme.palette.divider
    },
    bold: {
        fontWeight: "bold"
    }
}));

const CheckboxList = ({ name, options = [], valueKey, labelKey, descriptionKey, color = "primary", data, handleFieldChange, groupBy, onChange }) => {
    const classes = getStyles();
    const selected = options.filter(f => data[f[valueKey]]);
    const allSelected = selected?.length > 0 && selected?.length === options.length;

    const handleChange = value => () => {
        const dataCopy = { ...data };
        dataCopy[value] = !data[value];
        handleFieldChange({ name, value: dataCopy });
        if (typeof onChange === "function") onChange(value, dataCopy);
    };

    const handleSelectAll = () => {
        const dataCopy = { ...data };
        const value = allSelected ? false : true;
        options.forEach(opt => {
            dataCopy[opt[valueKey]] = value;
        });
        handleFieldChange({ name, value: dataCopy });
        if (typeof onChange === "function") onChange(valueKey, dataCopy);
    };

    if (groupBy){
        const all = options.map(opt => opt[groupBy]);
        const groups = all.filter((f, index) => all.indexOf(f) === index);
        groups.sort();

        return (
            <>
                <Checkbox color="primary" onClick={handleSelectAll} checked={allSelected} indeterminate={selected?.length > 0 && !allSelected} /> Select All
                <Grid full="true" container spacing={0} alignItems="stretch" className={classes.container}>
                    {groups.length ? groups.map((group, i) => {
                        const groupOptions = options.filter(f => f[groupBy] === group);
                        return (
                            <Grid item key={`${group}-${i}`} xs={12} sm={12} md={6} lg={4} className={classes.gridItem}>
                                <List dense disablePadding>
                                    <ListItem className={classes.listItem}>
                                        <ListItemText primary={group} primaryTypographyProps={{ variant: "h6", color, className: classes.bold }} />
                                    </ListItem>
                                    {groupOptions.length ? groupOptions.map((option, i) => (
                                        <ListItem className={classes.listItem} key={`${name}_${i}`} component={ButtonBase} onClick={handleChange(option[valueKey])} >
                                            <ListItemIcon>
                                                <Checkbox size="small" color={color} disableRipple checked={Boolean(data[option[valueKey]])} />
                                            </ListItemIcon>
                                            <ListItemText primary={option[labelKey]} secondary={option[descriptionKey]} />
                                        </ListItem>
                                    )) : null}
                                </List>
                            </Grid>
                        );
                    }) : null}
                </Grid>
            </>
        );
    }

    return (
        <>
            <Checkbox color="primary" onClick={handleSelectAll} checked={allSelected} indeterminate={selected?.length > 0 && !allSelected} /> Select All
            <Grid full="true" display="flex" container spacing={2}>
                {options.length ? options.map((option, i) => (
                    <Grid key={`${name}_${i}`} item xs={12} sm={12} md={6} lg={4}>
                        <Divider variant="middle" light />
                        <Box component={ButtonBase} onClick={handleChange(option[valueKey])} display="flex" alignItems="flex-start" margin={0} textAlign="left" padding={2}>
                            <Checkbox size="small" color={color} disableRipple checked={Boolean(data[option[valueKey]])} />
                            <Box>
                                <Typography>{option[labelKey]}</Typography>
                                <Typography color="secondary">{option[descriptionKey]}</Typography>
                            </Box>
                        </Box>
                    </Grid>
                )) : null}
            </Grid>
        </>
    );
};

CheckboxList.propTypes = {
    name: PropTypes.string.isRequired,
    valueKey: PropTypes.string.isRequired,
    labelKey: PropTypes.string.isRequired,
    descriptionKey: PropTypes.string.isRequired,
    options: PropTypes.array
};

const mapsStateToProps = (state, { name }) => {
    return {
        data: state.form.data[name],
        options: state.form.dropdowns[name]
    };
};

const mapDispatchToProps = { handleFieldChange };

export default connect(mapsStateToProps, mapDispatchToProps)(CheckboxList);
