import React from "react";
import PropTypes from "prop-types";
import { FormControl, FormHelperText, InputLabel, NativeSelect as MuiNativeSelect } from "@material-ui/core";

const NativeSelect = ({ label, name, value, variant = "outlined", onChange, error, helperText, options = [], optName, optVal, defaultLabel = "Choose one...", ...props }) => {
    return (
        <FormControl variant={variant} error={Boolean(error)}>
            {label ? <InputLabel>{label}</InputLabel> : null}
            <MuiNativeSelect
                {...props}
                value={value}
                name={name}
                onChange={onChange}
            >
                <option value="">{defaultLabel}</option>
                {options.map(option => (
                    <option key={option[optName]} value={option[optVal]}>
                        {option[optName]}
                    </option>
                ))}
            </MuiNativeSelect>
            {error || helperText ? <FormHelperText>{error || ""}{error && helperText ? " - " : ""}{helperText || ""}</FormHelperText> : null}
        </FormControl>
    );
};

NativeSelect.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    value: PropTypes.any,
    options: PropTypes.array,
    optName: PropTypes.string.isRequired,
    optVal: PropTypes.string.isRequired
};

export default React.memo(NativeSelect);
