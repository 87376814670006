import React from "react";
import { Button, Grid } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { uploadFileToServer } from "../actions/formActions";
import { hideDialog } from "../actions/layoutActions";
import Webcam from "react-webcam";

const WebcamCapture = ({ name }) => {
    const dispatch = useDispatch();
    const webcamRef = React.createRef(null);

    const handleCapture = () => {
        const screenshot = webcamRef.current.getScreenshot();
        let base64Image = screenshot.split(";base64,").pop();
        let blob = atob(base64Image).split("").map(function(c) {
            return c.charCodeAt(0);
        });
        let blobData = new Blob([new Uint8Array(blob)], { type: "image/png" });
        let file = new File([blobData], "screenshot.png");
        dispatch(uploadFileToServer({ route: "/api/image/upload", fieldName: name, data: file }));
        dispatch(hideDialog());
    };

    return (
        <Grid container direction="column" spacing={1}>
            <Grid item>
                <Webcam
                    audio={false}
                    screenshotFormat="image/jpeg"
                    height={720}
                    width={1280}
                    screenshotQuality={1}
                    style={{ padding: 0, margin: 0, maxWidth: "100%", height: "auto", maxHeight: "100%" }}
                    ref={webcamRef} />
            </Grid>
            <Grid item>
                <Button color="primary" variant="contained" onClick={handleCapture}>Capture</Button>
                <Button style={{ marginLeft: 8 }} variant="contained" onClick={() => dispatch(hideDialog())}>Cancel</Button>
            </Grid>
        </Grid>
    );
};

export default React.memo(WebcamCapture);
