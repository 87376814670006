import React from "react";
import { connect } from "react-redux";
import Phone from "./base/Phone";
import { handleFieldChange } from "../../actions/formActions";
import useDefValue from "./useDefValue";

const PhoneField = ({ name, handleFieldChange, onChange, ...allOtherProps }) => {
    useDefValue({ ...allOtherProps, name });
    
    const handleChange = e => {
        if (typeof onChange === "function") onChange(e);
        handleFieldChange({ name, value: e.target.value });
    };

    return <Phone {...allOtherProps} name={name} onChange={handleChange} />;
};

const mapsStateToProps = (state, { name }) => {
    return {
        value: state.form.data[name],
        error: state.form.errors[name]
    };
};

const mapDispatchToProps = { handleFieldChange };

export default connect(mapsStateToProps, mapDispatchToProps)(PhoneField);
