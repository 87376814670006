import short from "short-uuid";
import { useEffect } from "react";
import { callEndpointV2 } from "../actions/layoutActions";
import { useDispatch } from "react-redux";
import { handleFieldChange } from "../actions/formActions";

const useInteractionLogger = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        let interactionLog = [];
        const token = short.uuid();

        // adding a piece of the token to each interaction
        const a = token[6];
        const b = token[20];
        const c = token[25];

        const logInteraction = (type, data) => {
            interactionLog.push({ type, data, timestamp: Date.now(), src: `/${a}${b}${c}` });
        };

        let lastMouseMove = 0;
        const handleMouseMove = event => {
            if (Date.now() - lastMouseMove > 200) {
                logInteraction("mousemove", { x: event.clientX, y: event.clientY });
                lastMouseMove = Date.now();
            }
        };

        const handleKeypress = event => logInteraction("keypress", { key: event.key });
        const handleClick = event => logInteraction("click", { x: event.clientX, y: event.clientY });

        window.addEventListener("keypress", handleKeypress);
        window.addEventListener("mousemove", handleMouseMove);
        window.addEventListener("click", handleClick);

        const sendLogs = () => {
            dispatch(handleFieldChange({ name: "__formID", value: token }));
            if (interactionLog.length > 0) {
                dispatch(callEndpointV2({
                    endpoint: "/api/activity",
                    body: JSON.stringify({ token, interaction: interactionLog })
                }));
                interactionLog = []; // Clear the log after sending
            }
        };

        const interval = setInterval(sendLogs, 9000); // Send logs every 9 seconds

        return () => {
            window.removeEventListener("keypress", handleKeypress);
            window.removeEventListener("mousemove", handleMouseMove);
            window.removeEventListener("click", handleClick);
            clearInterval(interval);
        };
    }, [dispatch]);
};

export default useInteractionLogger;
