import React from "react";
import PropTypes from "prop-types";
import { TextField } from "@material-ui/core";
import { isNumeric } from "../../../helpers/utils";

const Number = ({ name, label, value, helperText, error, onChange, ...allOtherProps }) => {
    const {
        autoComplete,
        autoFocus,
        classes,
        className,
        color,
        defaultValue,
        disabled,
        FormHelperTextProps,
        fullWidth = true,
        InputLabelProps,
        InputProps = {},
        inputProps,
        inputRef,
        margin,
        onFocus,
        onBlur,
        placeholder,
        required,
        size,
        variant = "outlined"
    } = allOtherProps;

    const eligibleProps = {
        autoComplete,
        autoFocus,
        classes,
        className,
        color,
        defaultValue,
        disabled,
        FormHelperTextProps,
        fullWidth,
        InputLabelProps,
        InputProps,
        inputProps,
        inputRef,
        margin,
        onBlur,
        placeholder,
        required,
        size,
        variant
    };

    return (
        <TextField
            {...eligibleProps}
            id={name}
            label={label}
            value={isNumeric(value) ? value : ""}
            type="number"
            InputProps={{ ...InputProps, onFocus }}
            helperText={`${error || ""}${error && helperText ? " - " : ""}${helperText || ""}`}
            onChange={onChange}
            error={Boolean(error)}
        />
    );
};

Number.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

export default React.memo(Number);
